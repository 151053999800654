/* eslint-disable no-underscore-dangle */
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import WelloAvatar from '../WelloAvatar';
import CustomHighlight from './CustomHighlight';
import theme from '../../../Themes/muiTheme';
import { INDEX_NAMES } from '../../../Services/AlgoliaHelper';
import Routes from '../../../Routing/Routes';

type Props = {
  hits: Array,
  refine: Function,
  onSuggestionSelected: Function,
  value: String,
  onChange: Function,
  blockInteractions: Boolean,
  onEnterPress: Function,
  onBlur: Function,
  multiSection: Boolean,
};

const autosuggestTheme = {
  container: {
    position: 'relative',
  },
  input: {
    backgroundColor: 'white',
    width: '100%',
    height: '55px',
    padding: '10px 20px',
    fontSize: 15,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: '#c5c5c5',
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
  },
  inputFocused: {
    outline: 'none',
  },
  suggestionsContainer: {
    width: '100%',
    display: 'none',
    left: 0,
    right: 0,
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 56,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontWeight: 300,
    fontSize: 16,
    zIndex: 2000,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
};

const getSuggestionValue = hit => {
  switch (hit.a_index_name) {
    case INDEX_NAMES.SERVICE:
      return hit.a_label;
    case INDEX_NAMES.ORGANISATION:
      return `${hit.a_holding_name} - ${hit.a_name}`;
    case INDEX_NAMES.TWIG:
      return hit.description;
    case INDEX_NAMES.ARTICLE:
      return hit.title;
    default:
      return '';
  }
};

const getSectionSuggestions = section => section.hits;

function AutoCompleteAlgolia({
  hits,
  refine,
  onSuggestionSelected,
  value: inValue,
  onChange,
  blockInteractions,
  onEnterPress,
  onBlur,
  multiSection,
}: Props) {
  const [query, setQuery] = React.useState(inValue || '');
  const history = useHistory();
  const { t } = useTranslation();

  const handleChange =
    () =>
    (event, { newValue }) => {
      setQuery(newValue || '');
      onChange(newValue);
    };

  const handleSuggestionSelected = (event, { suggestion, sectionIndex }) => {
    if (sectionIndex === 0) {
      if (onSuggestionSelected) {
        if (suggestion.a_label) onSuggestionSelected(suggestion, -1);
      }
    } else if (sectionIndex === 1 && suggestion.a_slug) {
      if (blockInteractions) return;
      history.push(`/${suggestion.a_holding_slug}/${suggestion.a_slug}`);
    }
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    refine(value);
  };

  const handleSuggestionsClearRequested = () => {
    refine();
  };

  const inputProps = {
    placeholder: t(`${Routes.currentNamespace()}.SEARCH_BAR.PLACE_HOLDER`, {
      defaultValue: t('APP.SEARCH_BAR.PLACE_HOLDER'),
    }),
    onChange: handleChange(),
    value: query,
    onKeyPress: ev => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        onEnterPress();
      }
    },
    onBlur: event => {
      onBlur(event);
    },
  };

  const renderSuggestion = hit => {
    switch (hit.a_index_name) {
      case INDEX_NAMES.SERVICE:
        return (
          <Typography
            align="left"
            style={{
              color: theme.palette.secondary.dark,
            }}
          >
            <CustomHighlight attribute="a_label" hit={hit} tagName="mark" />
          </Typography>
        );
      case INDEX_NAMES.ORGANISATION:
        return (
          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            <WelloAvatar avatarUrl={hit.a_avatar} size={36} />
            <Grid>
              <Typography
                align="left"
                style={{
                  paddingLeft: '10px',
                  marginTop: '-4px',
                  color: theme.palette.secondary.dark,
                }}
              >
                <CustomHighlight attribute="a_holding_name" hit={hit} /> -{' '}
                <CustomHighlight attribute="a_name" hit={hit} />
              </Typography>
              <Typography
                align="left"
                style={{
                  fontSize: 12,
                  color: theme.palette.secondary.middle,
                  paddingLeft: '10px',
                }}
              >
                {t(`APP.SEARCH_BAR.${hit.a_type}.TITLE`)} - {hit.a_locality}
              </Typography>
            </Grid>
          </Grid>
        );
      case INDEX_NAMES.TWIG:
        return (
          <Typography align="left">
            <CustomHighlight attribute="description" hit={hit} tagName="mark" />
          </Typography>
        );
      case INDEX_NAMES.ARTICLE:
        return (
          <Typography align="left">
            <CustomHighlight attribute="title" hit={hit} tagName="mark" />
          </Typography>
        );
      default:
        return '';
    }
  };

  const renderSectionTitle = section => {
    if (section.hits.length === 0) return '';

    switch (section.index) {
      case INDEX_NAMES.SERVICE:
        return (
          <Typography
            align="left"
            style={{ fontWeight: 'bold', paddingLeft: '10px', fontSize: '12' }}
          />
        );
      case INDEX_NAMES.ORGANISATION:
        return (
          <>
            <Divider
              style={{
                borderColor: 'transparent',
                margin: '5px 0',
                color: theme.palette.secondary.dark,
              }}
            />
          </>
        );
      case INDEX_NAMES.TWIG:
        return (
          <Typography
            align="left"
            style={{ fontWeight: 'bold', paddingLeft: '10px', fontSize: '12' }}
          >
            {t('APP.SEARCH_RESULT_PAGE.TWIGS')}
          </Typography>
        );
      case INDEX_NAMES.ARTICLE:
        return (
          <Typography
            align="left"
            style={{ fontWeight: 'bold', paddingLeft: '10px', fontSize: '12' }}
          >
            {t('APP.SEARCH_RESULT_PAGE.ARTICLES')}
          </Typography>
        );
      default:
        return (
          <Typography align="left" style={{ fontWeight: 'bold' }}>
            {section.index}
          </Typography>
        );
    }
  };

  return (
    <Autosuggest
      suggestions={hits}
      multiSection={multiSection}
      onSuggestionSelected={handleSuggestionSelected}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      theme={autosuggestTheme}
    />
  );
}

export default AutoCompleteAlgolia;
