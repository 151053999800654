import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AdjustIcon from '@mui/icons-material/Adjust';
import RoomIcon from '@mui/icons-material/Room';
import SpeedIcon from '@mui/icons-material/Speed';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { coordinatesArrayToString } from '../../../Services/DataHelper';

import LeadMap from '../../Leads/LeadMap';

const useStyles = makeStyles(() => ({
  content: { textAlign: 'center', paddingTop: 0 },
  listItem: { padding: 0 },
  icon: { alignItems: 'center', color: 'black' },
  verticalDivider: { height: '10px', backgroundColor: 'black' },
  divider: { marginTop: 16, marginBottom: 16 },
}));

type Props = {
  tripInfo: Object,
  addressFrom: Object,
  addressTo: Object,
};

const CardTransportInfo = ({ tripInfo, addressFrom, addressTo }: Props) => {
  const classes = useStyles();
  // const { t } = useTranslation();

  const lead = {
    service: { service_category: { slug: 'transport' } },
    departure_from: addressFrom,
    arrival_to: addressTo,
    meta: {
      departureFormattedAddress: addressFrom?.formattedAddress,
      arrivalFormattedAddress: addressTo?.formattedAddress,
      geolocs: coordinatesArrayToString(tripInfo?.geolocs),
    },
  };

  return (
    <Card itemScope>
      <LeadMap lead={lead} height="280px" />

      <CardContent className={classes.content}>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.icon}>
            <AdjustIcon color="black" />
          </ListItemIcon>
          <ListItemText primary={addressFrom?.formattedAddress} />
        </ListItem>

        <ListItem className={classes.listItem} style={{ height: 8 }}>
          <ListItemIcon className={classes.icon} style={{ paddingLeft: 12 }}>
            <Divider orientation="vertical" color="black" className={classes.verticalDivider} />
          </ListItemIcon>
          <ListItemText />
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.icon}>
            <RoomIcon color="black" />
          </ListItemIcon>
          <ListItemText primary={addressTo?.formattedAddress} />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.icon}>
            <SpeedIcon color="black" />
          </ListItemIcon>
          {tripInfo?.distance && <ListItemText primary={`${tripInfo?.distance} kilomètres`} />}
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.icon}>
            <TimerOutlinedIcon color="black" />
          </ListItemIcon>
          {tripInfo?.duration && <ListItemText primary={`${tripInfo?.duration} minutes`} />}
        </ListItem>
      </CardContent>
    </Card>
  );
};

export default CardTransportInfo;
