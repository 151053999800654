import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  heroActivityLayout: {
    // Make the picture goes under the navbar
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    minHeight: '600px',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    marginTop: '4rem',
  },
  heroLeftContainer: {
    paddingTop: '2rem',
  },
  heroRightContainer: {
    paddingTop: '3rem',
    height: 300,
  },
  header: {
    fontWeight: '400',
  },
  heroTitle: {
    marginBottom: 0,
    fontWeight: 900,
  },
  heroSubTitle: {
    fontWeight: 400,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  heroRightPicture: {
    height: '180%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  button: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(5),
  },
}));

type Props = {
  title1: String,
  title2: String,
  title1Color1: String,
  title1Color2: String,
  title2Color1: String,
  title2Color2: String,
  subTitle: String,
  subTitleColor: String,
  cover: String,
  image: String,
  buttonLabel: String,
  btnOnRight: Boolean,
  btnColor: String,
  onButtonClick: Function,
};

function SectionSubHero(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title1,
    title1Color1,
    title1Color2,
    title2Color1,
    title2Color2,
    title2,
    subTitle,
    subTitleColor,
    cover,
    image,
    buttonLabel,
    btnOnRight,
    btnColor,
    onButtonClick,
  } = props;
  return (
    <Grid container>
      <Grid
        item
        align="left"
        className={classes.heroActivityLayout}
        style={{
          backgroundImage: `url(${cover})`,
        }}
      >
        <Grid container className={classes.layout}>
          <Grid item md={7} xs={12} className={classes.heroLeftContainer}>
            <Typography variant="h2" align="left" className={classes.heroTitle} gutterBottom>
              <div
                style={
                  title1Color1 &&
                  title1Color2 && {
                    background: `linear-gradient(to right, ${title1Color1}, ${title1Color2})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    WebkitBoxDecorationBreak: 'clone',
                  }
                }
              >
                {t(title1)}
              </div>
              <div
                style={
                  title2Color1 &&
                  title2Color2 && {
                    background: `linear-gradient(to right, ${title2Color1}, ${title2Color2})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    WebkitBoxDecorationBreak: 'clone',
                  }
                }
              >
                {t(title2)}
              </div>
            </Typography>

            <Typography
              variant="subtitle1"
              align="left"
              className={classes.heroSubTitle}
              paragraph
              style={subTitleColor && { color: `${subTitleColor}` }}
            >
              {t(subTitle)}
            </Typography>
            {buttonLabel && (
              <Grid align={btnOnRight ? 'left' : 'right'}>
                <Button
                  variant={btnColor ? 'contained' : 'outlined'}
                  disableElevation
                  className={classes.button}
                  onClick={onButtonClick}
                  style={
                    btnColor && {
                      backgroundColor: `${btnColor}`,
                      color: 'white',
                    }
                  }
                >
                  {t(buttonLabel)}
                </Button>
              </Grid>
            )}
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid item align="center" md={5} xs={12} className={classes.heroRightContainer}>
              <div
                className={classes.heroRightPicture}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionSubHero;
