import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    flexDirection: 'column',
  },
  fullWidthLayout: {
    ...LayoutStyles.fullWidthLayout,
    padding: theme.spacing(8, 0),
  },
  heroTitle: {
    lineHeight: 1.2,
  },
  heroSubTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
}));

type Props = {
  title: String,
  titleColor: String,
  title2: String,
  title2Color: String,
  subTitle: String,
  subTitleVariant: String,
  backgroundImage: String,
  children: Node,
  layoutHeight: String,
  backgroundColor: String,
  justifyContent: String,
};

function SectionHeroCenter({
  title,
  titleColor,
  title2,
  title2Color,
  subTitle,
  subTitleVariant,
  backgroundImage,
  children,
  layoutHeight,
  backgroundColor,
  justifyContent,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent={justifyContent || 'center'}
      className={classes.heroLayout}
      style={{
        backgroundImage: backgroundImage ? backgroundImage : null,
        backgroundColor: backgroundColor || '#000',
        minHeight: layoutHeight ? layoutHeight : '90vh',
      }}
    >
      <Grid align="center" className={classes.fullWidthLayout}>
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          style={{ color: titleColor }}
          className={classes.heroTitle}
        >
          {t(title)}
        </Typography>
        {title2 && (
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            style={{ color: title2Color }}
            className={classes.heroTitle}
          >
            {t(title2)}
          </Typography>
        )}
        {subTitle && (
          <Typography
            variant={subTitleVariant || 'body1next'}
            component={title2 ? 'h3' : 'h2'}
            className={classes.heroSubTitle}
          >
            {t(subTitle)}
          </Typography>
        )}
        <Grid item xs={12} sm={8}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionHeroCenter;
