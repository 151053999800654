import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Grid from '@material-ui/core/Grid';

type Props = {
  organisation: Object,
  tripPriceInfo: Object,
};

const useStyles = makeStyles(theme => ({
  textMargin: {
    margin: theme.spacing(2, 0),
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  extraCostInfo: {
    display: 'flex',
  },
}));

const TransportPriceSimulator = ({ organisation, tripPriceInfo }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const showExtraCostInfo =
    organisation?.slug === 'familles-rurales-avesnois-mormal' &&
    organisation.holding_slug === 'familles-rurales' &&
    tripPriceInfo.roundTrip &&
    !tripPriceInfo.needAccompagnement;

  return (
    <Grid>
      <Typography variant="h5" className={classes.textMargin}>
        {t('APP.QUOTATION.SIMULATOR.TITLE')}
      </Typography>
      {organisation?.slug === 'ambulance-pays-de-mormal' && (
        <Typography variant="caption">{t('APP.QUOTATION.SIMULATOR.MORMAL')}</Typography>
      )}
      <Typography variant="h6" className={classes.textMargin}>
        {t('MORMAL.SEARCH_RESULT_PAGE.PRICE')} : {tripPriceInfo.estimatedPrice?.toFixed(2)} &euro;{' '}
      </Typography>
      <Typography variant="h6" className={classes.textMargin}>
        {t('MORMAL.SEARCH_RESULT_PAGE.DISTANCE_DETAIL', {
          distance: tripPriceInfo?.distance,
        })}
      </Typography>
      <Typography variant="h6" className={classes.textMargin}>
        {t('MORMAL.SEARCH_RESULT_PAGE.DURATION_DETAIL', {
          duration: tripPriceInfo?.duration,
        })}
      </Typography>
      {showExtraCostInfo && (
        <Grid className={classes.extraCostInfo}>
          <InfoOutlinedIcon />
          <Typography variant="body2" className={classes.leftMargin}>
            {t('MORMAL.SEARCH_RESULT_PAGE.PRICE.EXTRA_COST')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default TransportPriceSimulator;
