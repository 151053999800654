import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useRouteMatch, useLocation } from 'react-router-dom';
import 'moment/locale/fr';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../Components/Layout/Footer';
import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../Routing/Routes';

import Seo from '../../Routing/Seo';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';

import { CustomHitsOrganisation, CustomHitsTwig } from '../../Components/Shared/Algolia/CustomHits';

import {
  INDEX_NAMES,
  FILTERING_ATTRIBUTES,
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  LEAGUE_SLUG,
  GEOGRAPHIC_LIMITS,
  formatType,
  wordifyType,
} from '../../Services/AlgoliaHelper';
import InstantSearchResults from '../../Components/Shared/Algolia/InstantSearchResults';

const useStyles = makeStyles(theme => ({
  activityUnit: {
    paddingBottom: theme.spacing(3),
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  subHeroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundImage: `url(${Images.sla.jumboSearchResults})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: '60%',
    padding: '3rem 0',
  },
  headingContainer: {
    width: '100%',
    padding: '3rem 0 3rem',
  },
  searchBar: {
    marginTop: 0,
  },
}));

function SearchResult() {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    params: { type_slug: typeSlug },
  } = match;
  const type = formatType(typeSlug);
  const typeName = wordifyType(typeSlug);
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    serviceSlug: queryParam.get('service') || '',
    city: queryParam.get('city') || null,
    address: queryParam.get('address') || '',
    latitude: queryParam.get('lat') || null,
    longitude: queryParam.get('long') || null,
    bbox: queryParam.get('bbox') || null,
  };
  const position = urlParams.latitude != null && urlParams.longitude != null;
  const bbox = urlParams.bbox?.split(';')?.map(val => parseFloat(val, 10)) || null;
  const dateNow = Math.floor(Date.now() / 1000);

  const typeAttributes = {
    organisations: {
      indexName: INDEX_NAMES.ORGANISATION,
      title: 'APP.SEARCH_RESULT_PAGE.ORGANISATIONS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 15,
        query: urlParams.serviceSlug,
        getRankingInfo: true,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.CD85}`,
      },
      HitsDisplay: CustomHitsOrganisation,
      hasMap: true,
      refinementAttributes: [
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
          attribute: FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE,
        },
      ],
      hideKeywordField: false,
      hideCityField: false,
    },
    twigs: {
      indexName: INDEX_NAMES.TWIG,
      title: 'APP.SEARCH_RESULT_PAGE.TWIGS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 12,
        // query: serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.CD85} AND ${FILTERING_ATTRIBUTES.TWIG_DATE_ATTRIBUTE} > ${dateNow}`,
      },
      HitsDisplay: CustomHitsTwig,
      hasMap: true,
      refinementAttributes: [
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
          attribute: FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE,
          limit: 30,
          showMoreLimit: 30,
        },
      ],
    },
  };

  return (
    <Grid>
      <Helmet>
        {urlParams.city &&
          ['organisations', 'twigs'].includes(type) &&
          Seo.title(
            `${typeName} à ${urlParams.city}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`,
          )}
        {(!urlParams.city || !['organisations', 'twigs'].includes(type)) &&
          Seo.title(`Résultats ${typeName}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
        {Seo.canonical(
          Routes.currentLeague().id,
          `${location.pathname}${location.search.replaceAll(' ', '%20')}`,
        )}
      </Helmet>
      <Hidden only={['xs']}>
        <Grid container className={classes.container}>
          <Grid item className={classes.subHeroLayout}>
            <Grid item className={classes.layout} style={{ paddingTop: 0 }}>
              <SearchBar
                className={classes.searchBar}
                service={urlParams.serviceSlug}
                address={urlParams.address}
                city={urlParams.city}
                latitude={urlParams.latitude}
                longitude={urlParams.longitude}
                type={type}
                goBackgrounColor={muiTheme.cd85.primary.main}
                geographicLimits={GEOGRAPHIC_LIMITS.CD85}
                searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.CD85}
                defaultType="structures"
                bbox={bbox}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <InstantSearchResults
        request={typeAttributes[type]}
        position={position ? { lat: urlParams.latitude, lng: urlParams.longitude } : null}
        typeAttributes={typeAttributes}
        bbox={bbox}
        tabs={[
          {
            label: t('APP.SEARCH_RESULT_PAGE.TAB.ORGANISATIONS'),
            value: 'organisations',
          },
          { label: t('APP.SEARCH_RESULT_PAGE.TAB.TWIGS'), value: 'twigs' },
        ]}
      />

      <Footer />
    </Grid>
  );
}

export default SearchResult;
