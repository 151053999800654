import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import HomeIcon from '@material-ui/icons/Home';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionWho from '../../Components/PagesLeagues/SectionWho';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import SectionSubHero from '../../Components/PagesLeagues/SectionSubHero';
import SectionArticles from '../../Components/PagesLeagues/SectionArticles';
import SectionTestimonial from '../../Components/PagesLeagues/SectionTestimonial';
import SectionActivity from '../../Components/PagesLeagues/SectionActivity';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { Images, Metrics, muiTheme } from '../../Themes';
import { OrientationBadgeIcon } from '../../Themes/IconBadge';
import { FormationIcon, CarteIcon } from '../../Themes/Icon';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';
import {
  LEAGUE_SLUG,
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  FILTERING_ATTRIBUTES,
  GEOGRAPHIC_LIMITS,
} from '../../Services/AlgoliaHelper';
import { getCdnUrl } from '../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  tabs: {
    position: 'sticky',
    top: Metrics.navBarHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: Metrics.navBarHeight - theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      top: Metrics.navBarHeight + theme.spacing(1),
    },
    backgroundColor: 'white',
    zIndex: '1010',
  },
  iconTab: {
    fontSize: 36,
    margin: 5,
  },
  textTab: {
    margin: 5,
  },
  homeTab: {
    maxWidth: 20,
    minWidth: 80,
    opacity: '1',
    color: 'white',
    backgroundColor: muiTheme.cd85.primary.main,
  },
  regularHomeTab: {
    maxWidth: 20,
    minWidth: 80,
  },
  labelContainer: {
    width: 'auto',
    textAlign: 'left',
  },
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  regularTab: {
    '&>*': {
      textTransform: 'none',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        justifyContent: 'center',
      },
    },
  },
  selectedSection1Tab: {
    color: 'white',
    backgroundColor: '#962DC6',
  },
  selectedSection2Tab: {
    color: 'white',
    backgroundColor: '#FFA70B',
  },
  selectedSection3Tab: {
    color: 'white',
    backgroundColor: '#F6C603',
  },
  selectedTab: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: theme.cd85.primary.main,
  },
  rootTabs: {
    backgroundColor: 'rgba(246,246,246)',
    boxShadow: '0 2px 10px 0 rgba(67,95,113,0.2)',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  subtitleWho: {
    width: '70%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      width: '90%',
    },
  },
  whoSubtitle: {
    margin: 'auto',
    textTransform: 'none',
    fontWeight: 400,
  },
  title: {
    marginBottom: theme.spacing(2),
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  subTitle: {
    marginBottom: theme.spacing(6),
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  imageMooc: {
    textAlign: 'center',
    '&>*': {
      width: '75%',
    },
  },
}));

function Homepage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const sectionRefs = React.useRef([]);
  sectionRefs.current.home = React.createRef();
  sectionRefs.current.section1 = React.createRef();
  sectionRefs.current.section2 = React.createRef();
  sectionRefs.current.section3 = React.createRef();

  const [tabValue, setTabValue] = React.useState('home');
  const handleScroll = () => {
    if (
      !window ||
      sectionRefs.current.section1.current === null ||
      sectionRefs.current.section2.current === null ||
      sectionRefs.current.section3.current === null
    )
      return;

    if (
      window.scrollY <
      sectionRefs.current.section1.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('home');
    } else if (
      window.scrollY <
      sectionRefs.current.section2.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('section1');
    } else if (
      window.scrollY <
      sectionRefs.current.section3.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('section2');
    } else {
      setTabValue('section3');
    }
  };

  React.useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll, true);
    }
    return () => (window ? window.removeEventListener('scroll', handleScroll) : null);
  }, []);

  const onTabValueChange = (event, newValue) => {
    event.preventDefault();
    if (sectionRefs.current[newValue] && sectionRefs.current[newValue].current) {
      window.scrollTo({
        top: sectionRefs.current[newValue].current.offsetTop - Metrics.navBarHeight - 50,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Grid className={classes.mainLayout} ref={sectionRefs.current.home} onScroll={handleScroll}>
      {/* HERO SECTION */}
      <SectionHero
        title1="Toutes les solutions"
        title2="pour les aidants en Vendée"
        subTitle=" "
        backgroundImage={`url(${Images.cd85.jumbo})`}
        title1Color1={muiTheme.cd85.primary.main}
        title1Color2={muiTheme.cd85.primary.main}
        title2Color1={muiTheme.cd85.primary.main}
        title2Color2={muiTheme.cd85.primary.main}
        headerColor="white"
        subTitleColor="white"
        imageMD={4}
      >
        <SearchBar
          goBackgrounColor={muiTheme.cd85.primary.main}
          geographicLimits={GEOGRAPHIC_LIMITS.CD85}
          searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.CD85}
          defaultType="structures"
        />
      </SectionHero>

      {/* TAB SECTION */}
      <Grid className={classes.tabs}>
        <Tabs
          value={tabValue}
          onChange={onTabValueChange}
          variant="fullWidth"
          classes={{
            root: classes.rootTabs,
            indicator: classes.indicator,
          }}
        >
          <Tab
            icon={<HomeIcon style={{ fontSize: 32, zIndex: '1000' }} />}
            value="home"
            classes={{
              root: classes.regularHomeTab,
              selected: classes.homeTab,
            }}
          />
          <Tab
            icon={<OrientationBadgeIcon className={classes.iconTab} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Accompagnement <br>individuel'),
                  }}
                />
              </Hidden>
            }
            value="section1"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection1Tab,
            }}
          />
          <Tab
            icon={<CarteIcon className={classes.iconTab} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Activités et <br>animations'),
                  }}
                />
              </Hidden>
            }
            value="section2"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection2Tab,
            }}
          />
          <Tab
            icon={<FormationIcon className={classes.iconTab} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Formations'),
                  }}
                />
              </Hidden>
            }
            value="section3"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection3Tab,
            }}
          />
        </Tabs>
      </Grid>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      {/* WHO ARE WE */}
      <Typography variant="subtitle1" paragraph align="center" className={classes.subtitleWho}>
        <p>
          L’aidant familial est « la personne non professionnelle qui vient en aide à titre
          principal, pour partie ou totalement, à une personne dépendante de son entourage, pour les
          activités de la vie quotidienne. Cette aide régulière peut être prodiguée de façon
          permanente ou non et peut prendre plusieurs formes, notamment: nursing, soins,
          accompagnement à l’éducation et à la vie sociale, démarches administratives, coordination,
          vigilance permanente, soutien psychologique, communication, activités domestiques, … ».
        </p>
      </Typography>
      <Typography variant="h5" paragraph align="center" className={classes.whoSubtitle}>
        <p>
          Je fais partie des <b>11 Millions</b> d’aidants en France
        </p>
        <p>
          Je suis <b>aux côtés d’un proche en perte d’autonomie</b>, en situation de handicap ou
          atteint d’une maladie
        </p>
        <p>
          Je <b>suis sollicité</b> régulièrement ou non pour aider mon proche
        </p>
        <p>
          Je cherche <b>des réponses</b> à mes questions
        </p>
      </Typography>
      <SectionWho
        title="Les solutions en Vendée"
        bullet={[
          {
            bullet: ' ',
            title: 'Que puis-je trouver sur ce site ?',
            content:
              '<p>Aidant-vendee.fr s’adresse <b>aux proches aidants</b>. Le site regroupe les solutions existantes en Vendée.</p><p>En quelques clics vous pouvez trouver de l’accompagnement, des activités, des formations et des informations à proximité de votre domicile.</p>',
          },
        ]}
        image={getCdnUrl(`https://www.wello.fr/${Images.cd85.illu}`, 800)}
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      {/* SOUTIEN SECTION */}
      <div ref={sectionRefs.current.section1}>
        <SectionSubHero
          title1="Se faire accompagner"
          title1Color1="#962DC6"
          title1Color2="#962DC6"
          title2="en tant qu’aidant"
          subTitle="Des structures locales existent afin de vous soutenir dans ce rôle et vous accompagner : informations, aide aux démarches administratives…"
          cover={Images.sla.jumbo_soutien}
          // image={getCdnUrl(`https://www.wello.fr/${Images.cd85.soutien}`)}
          image={getCdnUrl(`${Images.cd85.soutien}`)}
          buttonLabel="Trouver une structure"
          btnOnRight
          btnColor="#962DC6"
          onButtonClick={() =>
            history.push(
              `/recherche/structures?${FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE}=soutien-psychologique-individuel|demarches-administratives`,
            )
          }
        />
        <Divider style={{ margin: '5rem', background: 'none' }} />

        <SectionContent
          title="Bénéficier d’une écoute individualisée par un professionnel"
          h3
          paragraph="Plusieurs structures proposent du soutien psychologique vous permettant de vous exprimer (vos ressentis, vos émotions, votre parcours…)."
          image={getCdnUrl(`https://www.wello.fr/${Images.cd85.soutien_psy}`, 800)}
          imageOnLeft
          buttonLabel="Trouver du soutien psychologique"
          buttonLink={`/recherche/structures?${FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE}=soutien-psychologique-individuel`}
          rowItemTextSM={5}
        />

        <Divider style={{ margin: '5rem', background: 'none' }} />

        <SectionWho
          title="Trouver des solutions de répit"
          variantTitle="h3"
          bullet={[
            {
              bullet: ' ',
              title: 'Accueil de jour',
              content:
                '<p>Structure accueillant votre proche à la demi journée ou journée complète plusieurs fois par semaine.</p><p>Le bénéfice : une stimulation et accompagnement adapté pour votre proche et du temps libéré pour vous.</p>',
            },
            {
              bullet: ' ',
              title: 'Hebergement temporaire',
              content:
                '<p>Structure pouvant accueillir votre proche pour un séjour de quelques jours à quelques semaines.</p><p>Le bénéfice : un accompagnement au quotidien jour et nuit de votre proche et du temps pour vous ressourcer.</p>',
            },
            {
              bullet: ' ',
              title: 'Relayage à domicile',
              content:
                '<p>Un professionnel vient à votre domicile pour vous relayer dans l’accompagnement de votre proche.</p><p>Le bénéfice : un accompagnement personnalisé dans le confort de votre domicile.</p>',
            },
          ]}
          image={getCdnUrl(`${Images.cd85.repit}`)}
          // image={getCdnUrl(`https://www.wello.fr/${Images.cd85.repit}`)}
          imageOnRight
          buttonLabel="Trouver une solution de répit"
          onButtonClick={() =>
            history.push(
              `/recherche/structures?${FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE}=relayage-a-domicile|accueil-de-jour|hebergement-temporaire|halte-repit`,
            )
          }
        />

        <Divider style={{ margin: '3rem', background: 'none' }} />

        <SectionArticles
          title="Plus de ressources pour vous accompagner… "
          subTitle="Retrouvez des informations sur différents sujets autour de la perte d’autonomie, comment organiser le quotidien, trouver une aide à domicile, un hebergement permanent…"
          leagueSlug={LEAGUE_SLUG.CD85}
        />
      </div>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      {/* ACTIVITY SECTION  */}
      <div ref={sectionRefs.current.section2}>
        <SectionSubHero
          title1="Des moments conviviaux"
          title1Color1="#FFA70B"
          title1Color2="#FFA70B"
          title2="et de bien-être"
          subTitle="Ressourcez-vous grâce aux activités proposées par les acteurs du territoire. Que ce soit pour une balade, un temps convivial, des activités sportives ou de bien-être, accordez-vous une pause pour en bénéficier !"
          cover={Images.sla.jumbo_activity}
          // image={getCdnUrl(`https://www.wello.fr/${Images.cd85.activity}`, 800)}
          image={Images.cd85.activity}
          buttonLabel="DECOUVRIR TOUTES LES ACTIVITÉS"
          btnOnRight
          btnColor="#FFA70B"
          onButtonClick={() => history.push(`/recherche/activites`)}
        />

        <Divider style={{ margin: '3rem', background: 'none' }} />

        <SectionTestimonial
          title="Les aidants témoignent"
          subTitle="Les aidants apprécient de pouvoir prendre du temps pour eux"
          testimonials={[
            {
              avatar: Images.cd85.avatar_user_2,
              title: 'Martine – Aidante de son conjoint souffrant de la maladie de Parkinson',
              location: "Les Sables d'Olonne",
              content:
                '« Position très inconfortable pour l’aidant, culpabilisante, toujours peur de n’en pas faire assez, souvent sans reconnaissance ! Jusqu’au jour où la fatigue est là, sans la voir venir, par habitude… Merci pour les activités proposées et la convivialité qui va avec ! »',
            },
            {
              avatar: Images.cd85.avatar_user_1,
              title:
                'Frédérique – Aidante de son papa (maladie de Parkinson) et de sa maman (troubles de la mémoire)',
              location: "Les Sables d'Olonnes",
              content:
                "« Je remercie vivement le nid des aidants pour ses actions. Vous rencontrer et assister aux ateliers m'ont permis de me sentir moins seule. Grâce à vous, en prenant soin de moi, j'ai pu continuer d'accompagner mes proches dans la maladie et le vieillissement. Vous êtes une ressource importante. Je mesure la chance que j'ai eue de vous rencontrer. Merci. »",
            },
            // {
            //   avatar: Images.cd85.avatar_user_3,
            //   title: 'Marie - Aidante',
            //   location: 'La Roche-sur-Yon',
            //   content:
            //     '«Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tempus eu quam nec dignissim. Quisque vitae commodo massa. Phasellus ultrices consectetur felis eget porttitor. Maecenas nibh justo, finibus eu ipsum vitae, suscipit lobortis lorem. Aenean dapibus nibh lacinia mi elementum consectetur»',
            // },
          ]}
        />

        <Divider style={{ margin: '3rem', background: 'none' }} />

        <SectionActivity
          leagueSlug={LEAGUE_SLUG.CD85}
          expertises={[
            'activites-creatives',
            'activites-musicales',
            'activites-sportives',
            'jeux',
            'maintien-en-forme',
            'rencontres-conviviales',
            'pair-aidance-2',
            'detente-bien-etre',
            'groupes-de-parole-2',
          ]}
          buttonLabel="Découvrir toutes les activités"
          onButtonClick={() =>
            history.push(
              '/recherche/activites?a_expertise.slug=activites-creatives|activites-musicales|activites-sportives|jeux|maintien-en-forme|rencontres-conviviales|detente-bien-etre|groupes-de-parole-2|pair-aidance-2',
            )
          }
        />
      </div>
      <Divider style={{ margin: '3rem', background: 'none' }} />

      {/* INFORMATION SECTION  */}
      <div ref={sectionRefs.current.section3}>
        <SectionSubHero
          title1="Se former"
          title1Color1="#F6C603"
          title1Color2="#F6C603"
          title2="et être mieux préparé"
          subTitle="Des formations vous sont proposées par les différents acteurs du territoire afin d’appréhender la maladie de votre proche et de mieux la comprendre."
          cover={Images.sla.jumbo_information}
          // image={getCdnUrl(`https://www.wello.fr/${Images.cd85.course}`)}
          image={getCdnUrl(`${Images.cd85.course}`)}
          buttonLabel="TROUVER UNE STRUCTURE"
          btnOnRight
          btnColor="#F6C603"
          onButtonClick={() =>
            history.push(
              `/recherche/structures?${FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE}=formations`,
            )
          }
        />
        <Divider style={{ margin: '3rem', background: 'none' }} />

        <SectionActivity
          title="Des ateliers pour être épaulé dans son rôle d’aidant"
          subtitle="Des ateliers collectifs, des temps de rencontres, d’informations et de formations sont organisés avec des professionnels de santé."
          leagueSlug={LEAGUE_SLUG.CD85}
          expertises={[
            'ateliers-de-prevention',
            'ateliers-numeriques',
            'formations-2',
            'informations',
          ]}
          buttonLabel="Découvrir tous les ateliers"
          onButtonClick={() =>
            history.push(
              '/recherche/activites?a_expertise.slug=ateliers-de-prevention|ateliers-numeriques|formations-2|informations',
            )
          }
        />
      </div>

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une initiative du Nid des Aidants en partenariat avec WELLO et financé par la MSA"
        rowItemSM={4}
        partners={[
          {
            image: Images.cd85.logo_lna,
            titlePartner: 'Plateforme d’Accompagnement et de Répit',
            contentPartner:
              "Portée par l’EHPAD des Jardins d’Olonne (LNA Santé) et financée par l’ARS, « Le Nid des Aidants 85 » est un service public et gratuit ayant pour missions d'accompagner les proches aidants vivant au côté d’une personne atteinte d’une maladie neurodégénérative ou en perte d’autonomie.",
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
            // linkUrl: 'https://www.wello.fr/',
          },
          {
            image: Images.cd85.logo_msa,
            titlePartner: 'Sécurité sociale agricole',
            contentPartner:
              "2ème régime de protection sociale en France, la MSA délivre les prestations santé, famille-logement et retraite de la population agricole. L'aide aux aidants est un enjeu prioritaire de son plan d'action sanitaire et sociale.",
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
