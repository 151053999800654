import React from 'react';
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';
import CardTask from '../Organisations/Shared/CardTask';
import { searchClient, INDEX_NAMES, FILTERING_ATTRIBUTES } from '../../Services/AlgoliaHelper';
import { getTwigEndDate } from '../../Services/DataHelper';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 3rem 3rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem',
    },
  },
  rootActivity: {
    padding: theme.spacing(1, 1),
  },
  btn: {
    marginTop: theme.spacing(4),
  },
  subTitle: {
    marginBottom: theme.spacing(8),
  },
}));

type Props = {
  title: String,
  subtitle: String,
  leagueSlug: String,
  expertises: Array,
  buttonLabel: String,
  onButtonClick: Function,
  targetBlank: Boolean,
  children: Node,
};

function SectionActivity({
  title,
  subtitle,
  leagueSlug,
  expertises,
  buttonLabel,
  onButtonClick,
  targetBlank,
  children,
}: Props) {
  const getFilters = () => {
    if (expertises && leagueSlug) {
      return expertises.reduce((acc, item) => {
        return `${acc} OR ${FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE}:${item}`;
      }, `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${leagueSlug} AND ${FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE}:${expertises[0]} AND ${FILTERING_ATTRIBUTES.TWIG_DATE_ATTRIBUTE} > ${Math.floor(Date.now() / 1000)}`);
    }
    if (leagueSlug) {
      return `${FILTERING_ATTRIBUTES.TWIG_DATE_ATTRIBUTE} > ${Math.floor(Date.now() / 1000)} AND ${
        FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE
      }:${leagueSlug}`;
    }
    return '';
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={INDEX_NAMES.TWIG}>
      <Configure hitsPerPage={10} filters={getFilters()} />
      <CustomTwigsHits
        title={title}
        subtitle={subtitle}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
        targetBlank={targetBlank}
      >
        {children}
      </CustomTwigsHits>
    </InstantSearch>
  );
}

export default SectionActivity;

type HitsProps = {
  hits: Array,
  title: String,
  subtitle: String,
  buttonLabel: String,
  onButtonClick: Function,
  targetBlank: Boolean,
  children: Node,
};

const TwigsHits = ({
  hits,
  title,
  subtitle,
  buttonLabel,
  onButtonClick,
  targetBlank,
  children,
}: HitsProps) => {
  const twigs = hits?.map(hit => ({
    slug: hit.a_slug,
    excerpt: hit.a_excerpt,
    start_date: hit.a_event_date,
    until_date: hit.a_until_date,
    end_date: getTwigEndDate({
      task: { start_date: hit.a_start_date, end_date: hit.a_end_date },
      event_date: hit.a_event_date,
    }),
    cover_url: hit.a_cover_url || '',
    description: hit.a_description,
    holding_slug: hit.a_holding_slug,
    organisation_slug: hit.a_organisation_slug,
    organisation_name: hit.a_organisation_name,
    holding_name: hit.a_holding_name,
    expertise: hit.a_expertise,
    id: hit.objectID,
    formatted_address: hit.a_formatted_address,
    // eslint-disable-next-line no-underscore-dangle
    coordinates: hit._geoloc,
    registration: hit.a_registration,
    max_participants: hit.a_max_participants,
    occurrences: hit.a_occurrences,
    recurrence_type: hit.a_recurrence_type,
    recurrence_interval: hit.a_recurrence_interval,
    twig_for_date: {
      event_start_time: hit.a_event_start_time,
      event_end_time: hit.a_event_end_time,
    },
  }));
  const classes = useStyles();
  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 4,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {twigs && twigs.length > 0 && (
        <Grid className={classes.mainLayout}>
          <Grid className={classes.layout}>
            {title && (
              <Typography variant="h3" align="center" className={classes.title} gutterBottom>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="subtitle1" align="center" className={classes.subTitle} paragraph>
                {subtitle}
              </Typography>
            )}
            {children}
            <Slider {...settings}>
              {twigs.map(task => (
                <Paper className={classes.rootActivity} key={task.id} elevation={0}>
                  <CardTask task={task} elevation={1} targetBlank={targetBlank} />
                </Paper>
              ))}
            </Slider>
            {buttonLabel && (
              <Grid align="right">
                <Button onClick={onButtonClick} className={classes.btn}>
                  {buttonLabel}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const CustomTwigsHits = connectHits(TwigsHits);
