import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AccessibleIcon from '@material-ui/icons/Accessible';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import TransportPriceSimulator from '../Quotation/TransportPriceSimulator';
import { getHoursState } from '../../../Services/ServiceHelper';

const useStyles = makeStyles(theme => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  serviceIcon: {
    width: '24px',
    height: '24px',
    backgroundColor: 'white',
  },
  label: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
    alignSelf: 'center',
  },
  textMargin: {
    marginBottom: theme.spacing(2),
  },
  hoursMargin: {
    margin: theme.spacing(4, 0),
  },
  adaptedCar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  divider: {
    margin: theme.spacing(-1, 0, 4, 0),
  },
}));

type Props = {
  service: Object,
  isTransport: Boolean,
  tripPriceInfo: Object,
  openedAt: Object,
  showHeader: Boolean,
  organisation: Object,
};
function ServiceDetail({
  service,
  organisation,
  isTransport,
  tripPriceInfo,
  openedAt,
  showHeader,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const hoursState = getHoursState(openedAt);

  return (
    <Grid className={showHeader && classes.paddingTop}>
      {showHeader && (
        <>
          <Grid className={classes.header}>
            <Grid container style={{ marginTop: 8, flexWrap: 'nowrap' }}>
              <Avatar
                className={classes.serviceIcon}
                style={{
                  color: getExpertiseStyle(service?.expertise).color,
                }}
              >
                {getExpertiseStyle(service?.expertise).icon}
              </Avatar>
              <Typography variant="h4" component="h2" className={classes.label}>
                {service.label}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            className={classes.textMargin}
            dangerouslySetInnerHTML={{ __html: t(service.description) }}
          />
          {service.performed_by && service.performed_by !== 'null' && (
            <Typography variant="h6" component="p" className={classes.textMargin}>
              {t('APP.ORGANISATION.SETTINGS.SERVICE.PERFORMED')}
              {t(`APP.ORGANISATION.SETTINGS.SERVICE.PERFORMED.${service.performed_by}`)}
            </Typography>
          )}
        </>
      )}

      {isTransport && (
        <>
          {service.max_seat > 0 && (
            <Typography variant="body2" className={classes.textMargin}>
              {t('MORMAL.SEARCH_RESULT_PAGE.MAX_SEAT', { max_seat: service.max_seat })}
            </Typography>
          )}
          {service.adapted_car && (
            <Grid className={classes.adaptedCar}>
              <AccessibleIcon />
              <Typography variant="body2" className={classes.label}>
                {t('APP.SERVICE.TEXT_FIELD.LABEL.ADAPTED_CAR')}
              </Typography>
            </Grid>
          )}
          <Grid className={classes.hoursMargin}>
            {Object.keys(hoursState).map(day => (
              <Typography variant="body2" key={day}>
                {t(day.toUpperCase())} :
                {Object.values(hoursState[day]).length > 0
                  ? Object.values(hoursState[day])?.map(hour => ` ${hour.start} - ${hour.end}`)
                  : t('APP.SERVICE.SHOW.CLOSED_HOURS')}
              </Typography>
            ))}
          </Grid>
        </>
      )}
      <Grid className={classes.textMargin}>
        {service.escort_rate > 0 && (
          <Typography variant="body2" gutterBottom>
            {t(
              isTransport
                ? 'APP.SERVICE.TEXT_FIELD.LABEL.ESCORT_RATE_TRANSPORT'
                : 'APP.SERVICE.TEXT_FIELD.LABEL.ESCORT_RATE',
            )}{' '}
            : {service.escort_rate} &euro;
          </Typography>
        )}
        {service.price_description && (
          <Typography variant="caption">{service.price_description}</Typography>
        )}
      </Grid>

      {isTransport && tripPriceInfo?.estimatedPrice && (
        <TransportPriceSimulator organisation={organisation} tripPriceInfo={tripPriceInfo} />
      )}
    </Grid>
  );
}
export default ServiceDetail;
