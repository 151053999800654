import React from 'react';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { withStyles } from '@material-ui/core/styles';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';

type Props = {
  classes: Object,
  activeStep: Object,
  isOtherCategory: Boolean,
};

const styles = theme => ({
  layoutContainer: {
    ...LayoutStyles.fullWidthLayout,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  stepperContainer: {
    backgroundColor: 'white',
  },
});

function QuotationStepper({ activeStep, classes, isOtherCategory }: Props) {
  const { t } = useTranslation();
  const steps = [
    {
      id: 0,
      custom: `APP.QUOTATION.STEP_0.STEPPER`,
      default: `APP.QUOTATION.STEP_0.STEPPER`,
    },
    {
      id: 1,
      custom: `APP.QUOTATION.STEP_1.STEPPER`,
      default: `APP.QUOTATION.STEP_1.STEPPER`,
    },
  ];

  if (!isOtherCategory) {
    steps.push({
      id: 2,
      custom: `APP.QUOTATION.STEP_2.STEPPER`,
      default: `APP.QUOTATION.STEP_2.STEPPER`,
    });
  }

  return (
    <div className={classes.stepperContainer}>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.layoutContainer}>
        {steps.map(label => (
          <Step key={label.id}>
            <StepLabel>{t(label.custom, t(label.default))}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default withStyles(styles)(QuotationStepper);
