import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  footerLayout: {
    backgroundColor: grey[200],
    padding: theme.spacing(4),
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  resultsType: String,
};

const FooterSeo = ({ resultsType }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const links = {
    Organisation: [
      {
        label: 'Paris',
        url: '/recherche/structures?city=Paris&address=Paris,%20France&lat=48.85658&long=2.35183&bbox=2.22422400085346;48.8156060108013;2.46976999462145;48.9020129995121',
      },
      {
        label: 'Marseille',
        url: '/recherche/structures?city=Marseille&address=Marseille,%20Bouches-du-Rh%C3%B4ne,%20France&lat=43.296174&long=5.369952&bbox=5.228865;43.172598;5.532503;43.391017',
      },
      {
        label: 'Lyon',
        url: '/recherche/structures?city=Lyon&address=Lyon,%20Rh%C3%B4ne,%20France&lat=45.757814&long=4.832011&bbox=4.771718;45.707316;4.899015;45.808177',
      },
      {
        label: 'Toulouse',
        url: '/recherche/structures?city=Toulouse&address=Toulouse,%20Haute-Garonne,%20France&lat=43.604462&long=1.444247&bbox=1.350326;43.532694;1.515238;43.668525',
      },
      {
        label: 'Nice',
        url: '/recherche/structures?city=Nice&address=Nice,%20Alpes-Maritimes,%20France&lat=43.700936&long=7.268391&bbox=7.181893;43.645218;7.323423;43.760845',
      },
      {
        label: 'Nantes',
        url: '/recherche/structures?city=Nantes&address=Nantes,%20Loire-Atlantique,%20France&lat=47.218637&long=-1.554136&bbox=-1.641807;47.180648;-1.478816;47.295808',
      },
      {
        label: 'Montpellier',
        url: '/recherche/structures?city=Montpellier&address=Montpellier,%20H%C3%A9rault,%20France&lat=43.611242&long=3.876734&bbox=3.807033;43.566772;3.941151;43.653518',
      },
      {
        label: 'Strasbourg',
        url: '/recherche/structures?city=Strasbourg&address=Strasbourg,%20Bas-Rhin,%20France&lat=48.584614&long=7.750713&bbox=7.687934;48.491997;7.836113;48.646236',
      },
      {
        label: 'Bordeaux',
        url: '/recherche/structures?city=Bordeaux&address=Bordeaux,%20Gironde,%20France&lat=44.841225&long=-0.580036&bbox=-0.638901;44.810796;-0.533665;44.916188',
      },
      {
        label: 'Lille',
        url: '/recherche/structures?city=Lille&address=Lille,%20Nord,%20France&lat=50.636565&long=3.063528&bbox=2.967883;50.60057;3.125694;50.66122',
      },
      {
        label: 'Rennes',
        url: '/recherche/structures?city=Rennes&address=Rennes,%20Ille-et-Vilaine,%20France&lat=48.111339&long=-1.68002&bbox=-1.752606;48.076756;-1.624404;48.154894',
      },
      {
        label: 'Reims',
        url: '/recherche/structures?city=Reims&address=Reims,%20Marne,%20France&lat=49.257789&long=4.031926&bbox=3.985557;49.204103;4.123956;49.30316',
      },
      {
        label: 'Toulon',
        url: '/recherche/structures?city=Toulon&address=Toulon,%20Var,%20France&lat=43.125731&long=5.930492&bbox=5.87987;43.102304;5.987212;43.171435',
      },
      {
        label: 'Saint-Étienne',
        url: '/recherche/structures?city=Saint-%C3%89tienne&address=Saint-%C3%89tienne,%20Loire,%20France&lat=45.440147&long=4.387306&bbox=4.244097;45.371466;4.488921;45.476509',
      },
      {
        label: 'Le Havre',
        url: '/recherche/structures?city=Le%20Havre&address=Le%20Havre,%20Seine-Maritime,%20France&lat=49.493898&long=0.107973&bbox=0.066747;49.451483;0.195531;49.540022',
      },
    ],
    Twig: [
      {
        label: t('APP.EXPERTISE.CREATIVE'),
        url: '/recherche/activites?a_expertise.slug=activites-creatives',
      },
      {
        label: t('APP.EXPERTISE.MUSIC'),
        url: '/recherche/activites?a_expertise.slug=activites-musicales',
      },
      {
        label: t('APP.EXPERTISE.SPORT'),
        url: '/recherche/activites?a_expertise.slug=activites-sportives',
      },
      {
        label: t('APP.EXPERTISE.SHAPE'),
        url: '/recherche/activites?a_expertise.slug=maintien-en-forme',
      },
      {
        label: t('APP.EXPERTISE.GAME'),
        url: '/recherche/activites?a_expertise.slug=jeux',
      },
      {
        label: t('APP.EXPERTISE.MEETING'),
        url: '/recherche/activites?a_expertise.slug=rencontres-conviviales',
      },
      {
        label: t('APP.EXPERTISE.CULTURAL'),
        url: '/recherche/activites?a_expertise.slug=sorties-culturelles',
      },
      {
        label: t('APP.EXPERTISE.WELL_BEING'),
        url: '/recherche/activites?a_expertise.slug=detente-bien-etre',
      },
      {
        label: t('APP.EXPERTISE.SUPPORT_GROUPS'),
        url: '/recherche/activites?a_expertise.slug=groupes-de-parole-2',
      },
      {
        label: t('APP.EXPERTISE.PEER'),
        url: '/recherche/activites?a_expertise.slug=pair-aidance-2',
      },
      {
        label: t('APP.EXPERTISE.RESPITE'),
        url: '/recherche/activites?a_expertise.slug=repit',
      },
      {
        label: t('APP.EXPERTISE.HOLIDAYS'),
        url: '/recherche/activites?a_expertise.slug=sejours-de-repit-2',
      },
      {
        label: t('APP.EXPERTISE.PREVENTION'),
        url: '/recherche/activites?a_expertise.slug=ateliers-de-prevention',
      },
      {
        label: t('APP.EXPERTISE.DIGITAL'),
        url: '/recherche/activites?a_expertise.slug=ateliers-numeriques',
      },
      {
        label: t('APP.EXPERTISE.INFORMATION'),
        url: '/recherche/activites?a_expertise.slug=informations',
      },
    ],
  };

  return (
    <footer className={classes.footerLayout}>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3" className={classes.title}>
              {t(`SLA.SEARCH_RESULT_PAGE.SEO.TITLE.${resultsType}`)}
            </Typography>
          </Grid>
          {links[`${resultsType}`]?.slice(0, 5).map(link => (
            <Grid key={link.label}>
              <a href={link.url} className={classes.link}>
                <Typography variant="caption" color="textSecondary">
                  {t(`SLA.SEARCH_RESULT_PAGE.SEO.${resultsType}`, { link: link.label })}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Divider style={{ margin: '8px', backgroundColor: 'transparent' }} />
          {links[`${resultsType}`]?.slice(5, 10).map(link => (
            <Grid key={link.label}>
              <a href={link.url} className={classes.link}>
                <Typography variant="caption" color="textSecondary">
                  {t(`SLA.SEARCH_RESULT_PAGE.SEO.${resultsType}`, { link: link.label })}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Divider style={{ margin: '8px', backgroundColor: 'transparent' }} />
          {links[`${resultsType}`]?.slice(10, 15).map(link => (
            <Grid key={link.label}>
              <a href={link.url} className={classes.link}>
                <Typography variant="caption" color="textSecondary">
                  {t(`SLA.SEARCH_RESULT_PAGE.SEO.${resultsType}`, { link: link.label })}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </footer>
  );
};

export default FooterSeo;
