import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import IconButton from '@material-ui/core/IconButton';

import Divider from '@material-ui/core/Divider';

type Props = {
  classes: Object,
};

const styles = theme => ({
  nobullets: {
    listStyleType: 'none',
  },
  footerLayout: {
    backgroundColor: grey[200],
    padding: theme.spacing(4),
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
});

function FooterSla({ classes }: Props) {
  return (
    <footer className={classes.footerLayout}>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              Nos services
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/les-solutions-de-repit" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Solutions de répit
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/le-service-repit-bulle-d-air" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Relayage
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/le-soutien-aux-aidants" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Soutien aux aidants
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/informations-et-formations" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Informations
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a
              href="/articles/des-activites-et-ateliers-pour-les-aidants-et-les-aides"
              className={classes.link}
            >
              <Typography variant="caption" color="textSecondary">
                Activités
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/le-conge-de-proche-aidant" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Congé de proche aidant
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/les-services-des-plateformes-de-repit" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Services des plateformes de répit
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/articles/quelles-aides-financieres-pour-mon-proche" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Aides financières
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              Nous contacter
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="mailto:federation@soutenirlesaidants.fr" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Contacter la fédération des PFR
              </Typography>
            </a>
          </Grid>
          <Divider light style={{ margin: '3px', background: 'none' }} />
          <IconButton
            size="small"
            edge="start"
            href="https://www.linkedin.com/company/f%C3%A9d%C3%A9ration-des-plateformes-d-accompagnement-et-de-r%C3%A9pit-des-aidants-familiaux/"
            target="_blank"
            className={classes.fab}
          >
            <LinkedInIcon />
          </IconButton>
          <Divider light style={{ margin: '10px', background: 'none' }} />
        </Grid>
        <Grid item md={4} sm={4} xs={12} component="nav" className={classes.nobullets}>
          <Grid item md={12} component="li">
            <Typography variant="h6" component="h3">
              Nos plateformes de répit
            </Typography>
          </Grid>

          <Grid item md={12}>
            <a href="/la-scalaa/scalaa" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Maison des Aidants Métropole Lille
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a
              href="/maison-des-aidants-metropole-roubaix-tourcoing/roubaix"
              className={classes.link}
            >
              <Typography variant="caption" color="textSecondary">
                Maison des Aidants Métropole Roubaix Tourcoing
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/le-relais-des-aidants/bethune/" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Le Relais des Aidants Béthune
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/maison-des-aidants/mda-nantes" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Maison des aidants de Nantes
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a
              href="/pfr-du-douaisis/plateforme-d-accompagnement-et-de-repit-des-aidants-du-douaisis/"
              className={classes.link}
            >
              <Typography variant="caption" color="textSecondary">
                PFR du Douaisis
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/la-maison-d-alois/socx/" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                La Maison d&apos;Aloïs
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/maison-des-aidants-d-henin-carvin/la-scalaa-carvin" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Maison des Aidants d&apos;Hénin-Carvin
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/apei-les-papillons-blancs/lille" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Plateforme d&apos;accompagnement et de répit handicap Lille
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default withStyles(styles)(FooterSla);
