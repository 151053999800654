import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  headingContainer: {
    margin: '3rem auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: 0,
    },
  },
  partnersContainer: {
    justifyContent: 'center',
  },
  partners: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  cardLogo: {
    height: 240,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    [theme.breakpoints.only('xs')]: {
      height: 90,
    },
  },
}));

type Props = {
  title: String,
  rowItemSM: Number,
  partners: Array,
  width: String,
};

function SectionMorePartner(props: Props) {
  const classes = useStyles();
  const { title, rowItemSM, partners, width } = props;

  return (
    <Grid container align="center" className={classes.layout}>
      <Grid item className={classes.headingContainer} style={{ width: width || '100%' }}>
        <Typography variant="h3" align="center" gutterBottom>
          {title}
        </Typography>
        <Grid container align="center" className={classes.partnersContainer}>
          {partners.map(partner => (
            <Grid item xs={rowItemSM} key={partner.titlePartner} className={classes.partners}>
              <CardMedia
                className={classes.cardLogo}
                image={partner.image}
                title={partner.titlePartner}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionMorePartner;
