import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import HomeIcon from '@material-ui/icons/Home';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../Components/Layout/Footer';
import { Images, Metrics, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionWho from '../../Components/PagesLeagues/SectionWho';
import SectionSubHero from '../../Components/PagesLeagues/SectionSubHero';
import SectionActivity from '../../Components/PagesLeagues/SectionActivity';
// import SectionArticles from '../../Components/PagesLeagues/SectionArticles';
// import SectionMooc from '../../Components/PagesLeagues/SectionMooc';
import SectionNeed from '../../Components/PagesLeagues/SectionNeed';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import SectionCarousel from '../../Components/PagesLeagues/SectionCarousel';
// import SectionService from '../../Components/PagesLeagues/SectionService';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import {
  RandoBadgeIcon,
  HomeBadgeIcon,
  RepasBadgeIcon,
  CareBadgeIcon,
} from '../../Themes/IconBadge';
import {
  MenageIcon,
  LitIcon,
  ToiletteIcon,
  PortageRepasIcon,
  TransportIcon,
  AdministrativesIcon,
  AccompagnementIcon,
  NumeriqueIcon,
  TelephoneIcon,
  ServiceIcon,
  CourseIcon,
  CafeIcon,
} from '../../Themes/Icon';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';
import {
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  GEOGRAPHIC_LIMITS,
  LEAGUE_SLUG,
} from '../../Services/AlgoliaHelper';
import { getCdnUrl } from '../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  tabs: {
    position: 'sticky',
    top: Metrics.navBarHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: Metrics.navBarHeight - theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      top: Metrics.navBarHeight + theme.spacing(1),
    },
    backgroundColor: 'white',
    zIndex: '1',
  },
  iconTab: {
    fontSize: 36,
    margin: 5,
  },
  textTab: {
    margin: 5,
  },
  homeTab: {
    maxWidth: 20,
    [theme.breakpoints.up('xs')]: {
      minWidth: 80,
    },
    opacity: '1',
    color: 'white',
    backgroundColor: '#0B2442',
    '&>*': {
      '&>*': {
        '&>*': {
          fill: 'white',
        },
      },
    },
  },
  regularHomeTab: {
    maxWidth: 20,
    opacity: 1,
    [theme.breakpoints.up('md')]: {
      minWidth: 80,
    },
  },
  labelContainer: {
    width: 'auto',
    textAlign: 'left',
  },
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  regularTab: {
    padding: 0,
    opacity: 1,
    borderLeft: `solid 1px #cecece`,
    '&>*': {
      textTransform: 'none',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        justifyContent: 'center',
      },
    },
  },
  selectedSection1Tab: {
    color: 'white',
    backgroundColor: '#FFA70B',
    '&>*': {
      '&>*': {
        '&>*': {
          fill: 'white',
        },
      },
    },
  },
  selectedSection2Tab: {
    color: 'white',
    backgroundColor: '#53C264',
    '&>*': {
      '&>*': {
        '&>*': {
          fill: 'white',
        },
      },
    },
  },
  selectedSection3Tab: {
    color: 'white',
    backgroundColor: '#4069DB',
    '&>*': {
      '&>*': {
        '&>*': {
          fill: 'white',
        },
      },
    },
  },
  selectedSection4Tab: {
    color: 'white',
    backgroundColor: '#962DC6',
    '&>*': {
      '&>*': {
        '&>*': {
          fill: 'white',
        },
      },
    },
  },
  selectedTab: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: theme.ccmormal.secondary.main,
  },
  rootTabs: {
    backgroundColor: 'rgba(246,246,246)',
    boxShadow: '0 2px 10px 0 rgba(67,95,113,0.2)',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  heroImage: {
    position: 'absolute',
    top: 64,
    right: 0,
    height: '82vh',
  },
}));

function Homepage() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const sectionRefs = React.useRef([]);
  sectionRefs.current.home = React.createRef();
  sectionRefs.current.section1 = React.createRef();
  sectionRefs.current.section2 = React.createRef();
  sectionRefs.current.section3 = React.createRef();
  sectionRefs.current.section4 = React.createRef();

  const [tabValue, setTabValue] = React.useState('home');

  const handleScroll = () => {
    if (
      !window ||
      sectionRefs.current.section1.current === null ||
      sectionRefs.current.section2.current === null ||
      sectionRefs.current.section3.current === null ||
      sectionRefs.current.section4.current === null
    )
      return;

    if (
      window.scrollY <
      sectionRefs.current.section1.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('home');
    } else if (
      window.scrollY <
      sectionRefs.current.section2.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('section1');
    } else if (
      window.scrollY <
      sectionRefs.current.section3.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('section2');
    } else if (
      window.scrollY <
      sectionRefs.current.section4.current.offsetTop - Metrics.navBarHeight - 250
    ) {
      setTabValue('section3');
    } else {
      setTabValue('section4');
    }
  };

  React.useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll, true);
    }
    return () => (window ? window.removeEventListener('scroll', handleScroll) : null);
  }, []);

  const onTabValueChange = (event, newValue) => {
    event.preventDefault();
    if (sectionRefs.current[newValue] && sectionRefs.current[newValue].current) {
      window.scrollTo({
        top: sectionRefs.current[newValue].current.offsetTop - Metrics.navBarHeight - 50,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Grid className={classes.mainLayout} ref={sectionRefs.current.home} onScroll={handleScroll}>
      {/* HERO SECTION */}
      <SectionHero
        title1="Mormal et vous"
        body="Toutes les solutions pour bien vivre en pays de Mormal !"
        backgroundColor={muiTheme.ccmormal.secondary.main}
        title1Color1="#008F3B"
        title1Color2="#AED122"
        image={Images.ccmormal.hero}
        imageMD={5}
        imageWidth="auto"
        imageHeight="82vh"
        alignItems="center"
        bodyH2
        bodyColor="#fff"
        alignImage="right"
      >
        <Hidden only={['xs']}>
          <Divider style={{ margin: '2rem', background: 'none' }} />
        </Hidden>
        <SearchBar
          goBackgrounColor={muiTheme.ccmormal.primary.main}
          geographicLimits={GEOGRAPHIC_LIMITS.Mormal}
          searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.CCMormal}
          defaultType="structures"
          // showPathologiesFilter
          showPathologiesMormal
        />
      </SectionHero>

      {/* TAB SECTION */}
      <Grid className={classes.tabs}>
        <Tabs
          value={tabValue}
          onChange={onTabValueChange}
          variant="fullWidth"
          classes={{
            root: classes.rootTabs,
            indicator: classes.indicator,
          }}
        >
          <Tab
            icon={<HomeIcon style={{ fontSize: 42, zIndex: '1000', fill: '#0B2442' }} />}
            value="home"
            classes={{
              root: classes.regularHomeTab,
              selected: classes.homeTab,
            }}
          />
          <Tab
            icon={<RandoBadgeIcon className={classes.iconTab} style={{ fill: '#FFA70B' }} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Les loisirs et <br>les activités'),
                  }}
                />
              </Hidden>
            }
            value="section1"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection1Tab,
            }}
          />
          <Tab
            icon={<HomeBadgeIcon className={classes.iconTab} style={{ fill: '#53C264' }} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Entraide et<br>bénévolat'),
                  }}
                />
              </Hidden>
            }
            value="section2"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection2Tab,
            }}
          />
          <Tab
            icon={<RepasBadgeIcon className={classes.iconTab} style={{ fill: '#4069DB' }} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Les services et <br>les professionnels'),
                  }}
                />
              </Hidden>
            }
            value="section3"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection3Tab,
            }}
          />
          <Tab
            icon={<CareBadgeIcon className={classes.iconTab} style={{ fill: '#962DC6' }} />}
            label={
              <Hidden only={['sm', 'xs']}>
                <div
                  className={classes.textTab}
                  dangerouslySetInnerHTML={{
                    __html: t('Etre acteur <br>de sa santé'),
                  }}
                />
              </Hidden>
            }
            value="section4"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelContainer: classes.labelContainer,
              root: classes.regularTab,
              selected: classes.selectedSection4Tab,
            }}
          />
        </Tabs>
      </Grid>

      <Divider style={{ margin: '5rem', background: 'none' }} />

      {/* WHO ARE WE */}
      <SectionWho
        title="Les solutions en Pays de Mormal"
        bullet={[
          {
            bullet: ' ',
            title: 'Que puis-je trouver sur cette plateforme ?',
            content:
              '<p>Le pays de Mormal a dans son ADN la volonté d’instituer " le bien vivre " sur son territoire. Territoire solidaire, le pays de Mormal est à vos côtés afin de ne laisser personne sur le bord du chemin.</p><br><p><b>Seniors, personnes en situation de handicap, aidants</b></p><br><p><b>Mormal et vous</b> regroupe les services du territoire qui vous sont proposés. En quelques clics, vous pouvez trouver les solutions de proximité pour vous ou pour un proche.</p>',
          },
        ]}
        image={Images.ccmormal.benefit}
      />

      <Divider style={{ padding: '1rem', background: 'none' }} />

      {/* CLIC SECTION */}
      <SectionInfo
        title="Une question ?"
        body="Appelez-nous du Lundi au Vendredi de 8H30 à 12H00 et de 13H30 à 17h00, on se charge de tout !"
        phoneNumber="03 27 09 04 60"
        backgroundColor="#008F3C"
      />

      <Divider style={{ padding: '5rem', background: 'none' }} />

      {/* ACTIVITY SECTION */}
      <div ref={sectionRefs.current.section1}>
        <SectionSubHero
          title1="Des activités et des sorties"
          title1Color1="#FFA70B"
          title1Color2="#FFD31A"
          title2="près de chez vous"
          subTitle="Découvrez des sorties conviviales, des activités sportives ou de bien-être, des temps de loisirs partagés, des solutions de répit..."
          cover={Images.ccmormal.jumbo_activity}
          image={getCdnUrl(`https://www.wello.fr/${Images.ccmormal.activity}`, 800)}
          buttonLabel="Découvrir les activités"
          btnOnRight
          onButtonClick={() => history.push('/recherche/activites')}
        />
        <Hidden only={['xs']}>
          <Divider style={{ margin: '3rem', background: 'none' }} />
        </Hidden>
        <SectionActivity
          leagueSlug={LEAGUE_SLUG.CCMormal}
          buttonLabel="Découvrir toutes les activités"
          onButtonClick={() => history.push('/recherche/activites')}
        />
        <Divider style={{ margin: '3rem', background: 'none' }} />
        <SectionContent
          title="Activité Physique Adaptée"
          h3
          subtitle="Séances collectives animées par des professionnels"
          paragraph="Les différentes structures du Pays de Mormal vous aident à rester en forme grâce aux séances d'activité physique adaptée. Les séances, conçues par des professionnels, sont adaptées à vos capacités physiques et vous permettent d'atteindre vos objectifs : maintien de l'autonomie, entretien musculaire et articulaire, équilibre, coordination, ..."
          image={Images.ccmormal.home_activity}
          imageOnLeft
          buttonLabel="Découvrir"
          buttonLink="/recherche/activites?a_expertise.slug=maintien-en-forme|activites-sportives"
        />
      </div>

      <Divider style={{ padding: '5rem', background: 'none' }} />

      {/* HOMECARE SECTION */}
      <div ref={sectionRefs.current.section2}>
        <SectionSubHero
          title1="Entraide et bénévolat"
          title1Color1="#53C264"
          title1Color2="#A5DF65"
          title2="faites-vous aider"
          subTitle="Faites appel aux bénévoles des associations pour vous apporter de l’aide ponctuelle ou régulière : déposer des courses, faire un album photo numérique, changer une ampoule, se déplacer à un rdv..."
          cover={Images.ccmormal.jumbo_homecare}
          image={getCdnUrl(`https://www.wello.fr/${Images.ccmormal.homecare}`, 800)}
          buttonLabel="Trouvez de l'aide"
          btnOnRight
          onButtonClick={() => history.push(`/recherche/structures`)}
        />
        <Hidden only={['xs']}>
          <Divider style={{ margin: '3rem', background: 'none' }} />
        </Hidden>
        <SectionCarousel
          title="Des initiatives dans le Pays de Mormal"
          subtitle="Découvrez trois initiatives mis en oeuvre par les structures et associations"
          rowItemSM={4}
          initiatives={[
            // {
            //   title: 'Aide aux courses',
            //   subtitle: 'Portage des courses à domicile',
            //   paragraph:
            //     '<p>L’association La Rhônelle de Villereau met en place un service de portage de courses à domicile gratuit, à destination des seniors et des personnes en situation de handicap.</p><p>En partenariat avec la Communauté de Communes du Pays de Mormal dans le cadre de ses démarches Communauté Amie des Aînés et Handicap & Ruralité.</p>',
            //   image: Images.ccmormal.course,
            //   buttonLabel: 'Faire une demande',
            //   buttonLink: '/la-rhonelle/la-rhonelle-villereau',
            // },
            {
              title: 'Déplacez-vous',
              subtitle: 'Faciliter vos déplacements',
              paragraph:
                'Vous êtes un senior habitant le Pays de Mormal et vous avez besoin de vous déplacer ? Découvrez une nouvelle forme de mobilité. Cette plateforme vous permet de réserver facilement un transport adapté à votre besoin (taxi, transport solidaire, ambulance)',
              image: Images.ccmormal.sortie,
              buttonLabel: 'Découvrir',
              buttonLink: 'https://www.deplacezvous.fr/',
            },
            {
              title: 'Je vous visite',
              subtitle: 'Des bénévoles pour partager un temps convivial !',
              paragraph:
                '<p>Pour un temps d’échange partagé et recréer du lien social. Sous l’impulsion de la démarche Communauté amie des aînés du Pays de Mormal est né un partenariat avec les différentes institutions d’intervention à domicile (MSA, SSIAD…).</p><p>Porté par le Centre social et culturel de Landrecies, ce dispositif propose des temps de visites au domicile de la personne demandeuse entièrement gratuits, fondés sur le bénévolat actif et participatif de personnes sensibilisées et de toute confiance.</p>',
              image: Images.ccmormal.visite,
              // buttonLabel: 'Faire une demande',
              // buttonLink: '',
            },
          ]}
        />
        <Divider style={{ margin: '3rem', background: 'none' }} />
        <SectionNeed
          title="Les services proposés par les structures"
          rowItemSM={4}
          needs={[
            {
              icon: <CafeIcon />,
              title: 'Visite',
              subTitle:
                'Partager un café, un temps de lecture, une partie de cartes, ou un temps de discussion.',
            },
            {
              icon: <ServiceIcon />,
              title: 'Service de bricolage',
              subTitle:
                'Une ampoule à changer, une haie à tailler, nos bénévoles vous font profiter de leur passion.',
            },
            {
              icon: <CourseIcon />,
              title: 'Aide aux courses',
              subTitle:
                "Se faire déposer les courses chez soi car il est difficile de se déplacer ou qu'elles sont lourdes.",
            },
            {
              icon: <AccompagnementIcon />,
              title: 'Accompagnement sortie',
              subTitle:
                'Vous accompagner pour une promenade, un rendez-vous médical ou une activité de loisirs.',
            },
            {
              icon: <NumeriqueIcon />,
              title: 'Aide numérique',
              subTitle:
                'Aider à se servir de sa tablette, utiliser un réseau social ou encore faire un album photo...',
            },
            {
              icon: <TelephoneIcon />,
              title: 'Soutien téléphonique',
              subTitle:
                "Un p'tit coup de téléphone pour prendre des nouvelles et passer un bon moment.",
            },
          ]}
          iconColor="#53C264"
          buttonLabel="FAIRE UNE DEMANDE"
          onButtonClick={() => history.push(`/recherche/structures`)}
        />
      </div>

      <Divider style={{ padding: '5rem', background: 'none' }} />

      {/* PEOPLECARE SECTION */}
      <div ref={sectionRefs.current.section3}>
        <SectionSubHero
          title1="Trouvez un professionnel"
          title1Color1="#4069DB"
          title1Color2="#7ED0E6"
          title2="et échangez avec lui"
          subTitle="Trouvez des professionnels de l’aide à domicile pour tous les gestes de la vie quotidienne (ménage, repas, lever, toilette…)."
          cover={Images.ccmormal.jumbo_peoplecare}
          image={getCdnUrl(`https://www.wello.fr/${Images.ccmormal.peoplecare}`, 800)}
          buttonLabel="Trouvez un professionnel"
          btnOnRight
          onButtonClick={() => history.push(`/recherche/structures`)}
        />
        <Divider style={{ margin: '3rem', background: 'none' }} />
        <SectionNeed
          title="Les services de l’aide à domicile"
          rowItemSM={4}
          needs={[
            {
              icon: <MenageIcon />,
              title: 'Entretien du logement',
              subTitle:
                'Faire appel à des professionnels pour réaliser le ménage et entretenir son logement.',
            },
            {
              icon: <ToiletteIcon />,
              title: 'Aide à la toilette',
              subTitle:
                "Une aide à la toilette et à l'habillage dans les règles d'hygiène et de sécurité.",
            },
            {
              icon: <LitIcon />,
              title: 'Aide au lever/coucher',
              subTitle:
                'Une aide à effectuer les mouvements qui permettent de se coucher ou de se lever.',
            },
            {
              icon: <PortageRepasIcon />,
              title: 'Préparation des repas',
              subTitle: "Préparation par un professionnel d'un repas sain et adapté.",
            },
            {
              icon: <TransportIcon />,
              title: 'Transport',
              subTitle:
                'Un accompagnement pour les courses, des rendez-vous, ou rendre visite à un proche.',
            },
            {
              icon: <AdministrativesIcon />,
              title: 'Démarches administratives',
              subTitle: "Le service permet d'être accompagné dans ses démarches administratives.",
            },
          ]}
          iconColor="#4069DB"
          buttonLabel="FAIRE UNE DEMANDE"
          onButtonClick={() => history.push(`/recherche/structures`)}
        />
      </div>

      <Divider style={{ padding: '5rem', background: 'none' }} />

      {/* HEALTHCARE SECTION */}
      <div ref={sectionRefs.current.section4}>
        <SectionSubHero
          title1="Informez-vous"
          title1Color1="#962DC6"
          title1Color2="#D678CE"
          title2="et échangez avec des pairs"
          subTitle="Trouvez des ateliers de bien-être pour prendre soin de vous et des groupes de paroles pour partager votre expérience entre pairs."
          cover={Images.ccmormal.jumbo_healthcare}
          image={getCdnUrl(`https://www.wello.fr/${Images.ccmormal.healthcare}`)}
          // buttonLabel="Trouvez une structure"
          // btnOnRight
          // onButtonClick={() =>
          //   history.push(
          //     `/recherche/structures`,
          //   )
          // }
        />
        <Hidden only={['xs']}>
          <Divider style={{ margin: '3rem', background: 'none' }} />
        </Hidden>

        <SectionActivity
          title="Des ateliers pour votre bien être"
          subtitle="Yoga, sophrologie, qi-gong…accordez-vous une pause et profitez de ces moments de détente…"
          leagueSlug={LEAGUE_SLUG.CCMormal}
          expertises={['detente-bien-etre', 'groupes-de-parole-2']}
          // buttonLabel="Découvrir toutes les activités"
          // onButtonClick={() => history.push('/recherche/activites')}
        />

        {/* <Divider style={{ margin: '5rem', background: 'none' }} />

        <SectionContent
          title="Des communautés pour vous aider"
          h3
          paragraph="<p>Rejoignez une structure associative sur la plateforme numérique pour accéder aux groupes de parole.</p><p>Vous ferez ainsi parti de communautés d’aidants pour partager avec des professionnels et d’autres aidants vos expériences, vos problématiques, vos joies, vos astuces !</p><p>Vous pourrez également vous informer sur la pathologie de votre proche et obtenir du soutien psychologique.</p>"
          image={getCdnUrl(`https://www.wello.fr/${Images.ccmormal.community}`, 800)}
          imageOnLeft
        />

        <Divider style={{ margin: '5rem', background: 'none' }} />

        <Divider style={{ margin: '5rem', background: 'none' }} />

        <SectionMooc
          title="Des vidéos pour vous informer et vous former"
          subTitle="Retrouvez des vidéos sur différentes thématiques, en lien notamment avec le répit,  la perte d’autonomie, le role d’aidant…"
          // buttonLabel="Découvrir toutes les vidéos"
          // onButtonClick={() => history.push('/recherche/formations')}
        /> */}
      </div>

      <Divider style={{ padding: '3rem', background: 'none' }} />

      {/* PARTNER SECTION */}
      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une initiative de la Communauté de Communes du Pays de Mormal en partenariat avec Wello."
        rowItemSM={6}
        partners={[
          {
            image: Images.mormal.partner,
            titlePartner: 'MORMAL.HOMEPAGE.PARTNER.PARTNER_MORMAL.TITLE',
            contentPartner: 'MORMAL.HOMEPAGE.PARTNER.PARTNER_MORMAL.CONTENT',
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
          },
        ]}
      />

      <Divider style={{ padding: '3rem', background: 'none' }} />

      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
