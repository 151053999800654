import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Hidden from '@material-ui/core/Hidden';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Fab } from '@material-ui/core';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionWho from '../../Components/PagesLeagues/SectionWho';
import SectionArticles from '../../Components/PagesLeagues/SectionArticles';
import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import SectionMooc from '../../Components/PagesLeagues/SectionMooc';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import SectionMorePartner from '../../Components/PagesLeagues/SectionMorePartner';
import FooterSla from './Footer';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { Images, Metrics, muiTheme } from '../../Themes';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';
import {
  formatType,
  LEAGUE_SLUG,
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  sluggifyType,
} from '../../Services/AlgoliaHelper';
// import ButtonCallMeBackInModal from '../../Components/Shared/ButtonCallMeBackInModal';
// import { organisationSlaCTA } from '../../Services/OrganisationHelper';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem 6rem',
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  tabs: {
    position: 'sticky',
    top: Metrics.navBarHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: Metrics.navBarHeight - theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      top: Metrics.navBarHeight + theme.spacing(1),
    },
    backgroundColor: 'white',
    zIndex: '1010',
  },
  tab: {
    [theme.breakpoints.only('xs')]: {
      textTransform: 'none',
      '&>*': {
        fontSize: '10px',
      },
    },
  },
  iconTab: {
    fontSize: 36,
    margin: 5,
  },
  textTab: {
    margin: 5,
  },
  homeTab: {
    maxWidth: 20,
    minWidth: 80,
    opacity: '1',
    color: 'white',
    backgroundColor: '#5E2677',
  },
  regularHomeTab: {
    maxWidth: 20,
    minWidth: 80,
  },
  labelContainer: {
    width: 'auto',
    textAlign: 'left',
  },
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  regularTab: {
    '&>*': {
      textTransform: 'none',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        justifyContent: 'center',
      },
    },
  },
  selectedSection1Tab: {
    color: 'white',
    backgroundColor: '#F6C603',
  },
  selectedSection2Tab: {
    color: 'white',
    backgroundColor: '#FF6B17',
  },
  selectedSection3Tab: {
    color: 'white',
    backgroundColor: '#962DC6',
  },
  selectedSection4Tab: {
    color: 'white',
    backgroundColor: '#FFA70B',
  },
  selectedTab: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: theme.mormal.primary.main,
  },
  rootTabs: {
    backgroundColor: 'rgba(246,246,246)',
    boxShadow: '0 2px 10px 0 rgba(67,95,113,0.2)',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  subtitleWho: {
    width: '50%',
    margin: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  subTitle: {
    marginBottom: theme.spacing(6),
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  ctaContainer: {
    ...LayoutStyles.fullWidthLayout,
    margin: '5rem 3rem 5rem',
    padding: '3rem 3rem 3rem',
    borderRadius: '4px',
    backgroundColor: theme.sla.primary.main,
    '&>*': { color: '#fff' },
  },
  btn: {
    marginTop: theme.spacing(3),
  },
}));

function Homepage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const sectionRefs = React.useRef([]);
  sectionRefs.current.home = React.createRef();
  sectionRefs.current.section1 = React.createRef();
  sectionRefs.current.section2 = React.createRef();
  sectionRefs.current.section3 = React.createRef();
  sectionRefs.current.section4 = React.createRef();
  const match = useRouteMatch();
  const {
    params: { type_slug: typeSlug },
  } = match;
  const type = formatType(typeSlug);

  const onTabValueChange = (event, newValue) => {
    let { pathname } = location;
    const formattedValue = sluggifyType(newValue);

    if (!type) {
      pathname = `/recherche/${formattedValue}${pathname}`;
    } else if (formattedValue !== typeSlug) {
      pathname = pathname.replace(typeSlug, formattedValue);
    }

    const historyToPush = `${pathname}${location.search}`;
    history.push(historyToPush);
  };

  return (
    <Grid className={classes.mainLayout} ref={sectionRefs.current.home}>
      {/* HERO SECTION */}
      <SectionHero
        header="J’accompagne mon proche…"
        title1="Qui peut m’aider ?"
        backgroundImage={`url(${Images.sla.jumbo}?v2)`}
        title1Color1="white"
        title1Color2="white"
        title2Color1="white"
        title2Color2="white"
        headerColor="white"
        imageMD={4}
        layoutHeight="80vh"
      >
        <Divider style={{ margin: '2rem', background: 'none' }} />
        <SearchBar
          goBackgrounColor={muiTheme.sla.primary.main}
          searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.SLA}
          defaultType="structures"
          showPathologiesFilter
          hideKeywordField
          AutoCompleteWidth={9}
        />
        <Hidden only={['xs']}>
          <Grid item sm={9} md={8}>
            <Grid container wrap="nowrap" alignItems="center" style={{ padding: '32px 0' }}>
              <Typography variant="h3" component="h2" style={{ color: 'white', fontWeight: 400 }}>
                Découvrez nos activités, articles, formations et communautés !
              </Typography>
              <Fab
                style={{ backgroundColor: `white`, flexShrink: 0 }}
                onClick={() => history.push('/recherche/activites')}
              >
                <ChevronRightIcon fontSize="large" />
              </Fab>
            </Grid>
          </Grid>
        </Hidden>
      </SectionHero>

      {/* TAB SECTION */}
      <Grid className={classes.tabs}>
        <Paper square>
          <Tabs
            value={type}
            indicatorColor="primary"
            textColor="primary"
            onChange={onTabValueChange}
            scrollButtons="auto"
            variant="fullWidth"
          >
            {[
              {
                label: t('APP.SEARCH_RESULT_PAGE.TAB.ORGANISATIONS'),
                value: 'organisations',
              },
              { label: t('APP.SEARCH_RESULT_PAGE.TAB.TWIGS'), value: 'twigs' },
              {
                label: t('APP.SEARCH_RESULT_PAGE.TAB.ARTICLES'),
                value: 'articles',
              },
              { label: t('APP.SEARCH_RESULT_PAGE.TAB.CLUBS'), value: 'clubs' },
              {
                label: t('SLA.SEARCH_RESULT_PAGE.TAB.COURSES'),
                value: 'courses',
              },
            ].map(tab => (
              <Tab key={tab.label} label={tab.label} value={tab.value} className={classes.tab} />
            ))}
          </Tabs>
        </Paper>
      </Grid>

      <Divider style={{ margin: '4rem', background: 'none' }} />

      {/* WHO ARE WE */}
      <Grid className={classes.layout}>
        <Typography
          variant="h3"
          component="h2"
          align="center"
          gutterBottom
          style={{ marginBottom: 30 }}
        >
          Bienvenue sur le site dédié aux aidants familiaux
        </Typography>
        <Typography variant="subtitle1" paragraph align="center" className={classes.subtitleWho}>
          S&apos;occuper d&apos;un proche en perte d’autonomie, d&apos;un enfant ou d&apos;un adulte
          en situation de handicap est parfois une activité à temps plein, les professionnels des
          plateformes de répit sont là pour vous soutenir.
        </Typography>
        <Divider style={{ margin: '2rem', background: 'none' }} />
      </Grid>

      <SectionWho
        bullet={[
          {
            bullet: ' ',
            title: 'Vous informer et vous orienter près de chez vous',
            content:
              "La maladie d'Alzheimer ou troubles apparentés, la maladie de Parkinson, le handicap (moteur, mental, auditif, visuel…) se répercutent sur l'ensemble de la famille et plus particulièrement sur les aidants familiaux. Les professionnels des plateformes de répit sont là pour vous informer sur l'accompagnement de votre proche et vous orienter vers des solutions sur votre territoire. Les plateformes de répit peuvent également vous proposer des formations collectives et vous aider à l’ouverture de droits et à la conduite de démarches administratives.",
          },
          {
            bullet: ' ',
            title: 'Du soutien aux aidants individuel et collectif',
            content:
              'Être aidant rime souvent avec besoin d’être aidé également… Les psychologues des plateformes de répit vous accompagnent lors d’entretiens individuels dans votre relation avec votre proche et dans l’évolution de sa situation. Les professionnels animent également des groupes de paroles dans lesquels le partage d’expériences et les conseils entre pairs vous aident à comprendre et à vivre votre rôle d’aidant.',
          },
          {
            bullet: ' ',
            title: 'Des solutions de répit à domicile ou en structure',
            content:
              "Le répit c’est permettre qu'un professionnel assure une prise en soin temporaire du proche, chez soi ou dans un établissement pour apporter un soulagement à l’aidant principal et ainsi éviter son épuisement. Les solutions de répit, de quelques heures à quelques jours, permettent à l’aidant d’avoir du temps libéré, pour lui.",
          },
          {
            bullet: ' ',
            title: 'Des activités et des sorties rien que pour vous',
            content:
              'Les associations de votre territoire vous proposent de participer à des activités sportives, de découvrir des loisirs créatifs et de partager des moments de convivialité autour de sorties culturelles, etc. Ces activités sont diverses et chacun, en fonction de ses préférences et de ses envies, peut trouver une activité qui lui correspond, et rencontrer de nouvelles personnes. Certaines activités se partagent également avec votre proche.',
          },
        ]}
        image={Images.sla.infographie}
      />

      <SectionInfo
        title="Évaluez votre risque d'épuisement"
        subTitle="Grâce à l'outil d'évaluation « J'aide, je m'évalue » de la Fondation France Répit"
        buttonTitle="Accéder au questionnaire"
        buttonUrl="https://jaidejemevalue.fr/"
        backgroundColor="rgb(39,119,128)"
        targetBlank
      />

      {/* INFORMATION SECTION */}
      <div ref={sectionRefs.current.section1}>
        <SectionArticles
          title="Les dernières actualités"
          subTitle="Retrouvez des informations concernant les pathologies, la législation, le rôle d'aidant, les démarches administratives, les nouvelles aides, etc."
          buttonLabel="Découvrir tous les articles"
          onButtonClick={() => history.push('/recherche/articles')}
          leagueSlug={LEAGUE_SLUG.SLA}
        />
        <Divider style={{ margin: '2rem', background: 'none' }} />
        <SectionInfo
          title="Revivez la Journée Nationale des Aidants"
          subTitle="Retrouvez les vidéos de la JNA du 12 octobre 2023 !"
          buttonTitle="Accéder aux vidéos"
          buttonUrl="/video"
          backgroundColor={muiTheme.sla.primary.main}
        />
        <Divider style={{ margin: '5rem', background: 'none' }} />
        <SectionMooc
          title="Des parcours de formation en ligne"
          subTitle="Nous vous proposons des formations en ligne pour vous informer et vous former sur différentes thématiques telles que le rôle d’aidant, la perte d’autonomie, les pathologies, ..."
          buttonLabel="Découvrir toutes les formations"
          onButtonClick={() => history.push('/recherche/formations')}
          leagueSlug={LEAGUE_SLUG.SLA}
        />
      </div>

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une initiative de la fédération des PlateFormes d'accompagnement et de Répit en partenariat avec WELLO"
        rowItemSM={12}
        partners={[
          {
            image: Images.sla.partner,
            titlePartner: 'Renforcer l’aide aux aidants',
            contentPartner:
              'Regroupées sous forme de fédération, les plateformes d’accompagnement et de répit pour les aidants familiaux travaillent en collaboration avec tous les partenaires du territoire pour renforcer l’aide aux aidants et contribuer à la reconnaissance du rôle de l’aidant.',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />

      <SectionMorePartner
        title="Nos partenaires"
        width="80%"
        rowItemSM={4}
        partners={[
          {
            image: Images.sla.malakoff,
            titlePartner: 'Malakoff Humanis',
          },
          {
            image: Images.sla.ars,
            titlePartner: 'ARS',
          },
          {
            image: Images.sla.aesio,
            titlePartner: 'AESIO',
          },
          {
            image: Images.sla.cnsa,
            titlePartner: 'CNSA',
          },
          {
            image: Images.sla.afnic,
            titlePartner: 'Fondation Afnic',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <FooterSla />
      <Footer />
    </Grid>
  );
}

export default Homepage;
