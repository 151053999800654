import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';
import { PartnerCard } from '../Block';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  partners: {
    padding: '0 30px',
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
}));

type Props = {
  title: String,
  subTitle: String,
  rowItemSM: Number,
  partners: Array,
};

function SectionPartner(props: Props) {
  const classes = useStyles();
  const { title, subTitle, rowItemSM, partners } = props;
  const { t } = useTranslation();

  return (
    <Grid container align="center" className={classes.layout}>
      <Grid item className={classes.headingContainer}>
        <Typography variant="h3" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
          {subTitle}
        </Typography>
        <Grid container align="center">
          {partners.map(partner => (
            <Grid
              item
              md={rowItemSM}
              xs={12}
              className={classes.partners}
              key={partner.titlePartner}
            >
              <PartnerCard
                key={partner.titlePartner}
                img={partner.image}
                title={t(partner.titlePartner)}
                content={t(partner.contentPartner)}
              />
              {partner.linkUrl && (
                <Button color="inherit" href={partner.linkUrl} target="_blank">
                  {t('KNOW_MORE')}
                </Button>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionPartner;
