/* eslint-disable react/no-danger */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import moment from 'moment';
import 'moment/locale/fr';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import _uniqBy from 'lodash/uniqBy';

import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Footer from '../../Components/Layout/Footer';

import CardOrganisationTransport from '../../Components/Organisations/Shared/CardOrganisationTransport';
import OrganisationsLeagueActions from '../../Redux/OrganisationsLeagueRedux';
import { hasTransport, isSap, isAssociation, isOther } from '../../Acl/Controls';
import { Images } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../Routing/Routes';

import Seo from '../../Routing/Seo';
import SearchBar from './SearchBar';
import EmptyList from '../../Components/Shared/EmptyList';
import { DEFAULT_HOUR_START, DEFAULT_HOUR_END } from '../../Components/Posts/InputMultiHours';
import { formatHours, getTripInfo, isOutOfTimeRange } from '../../Services/DataHelper';
import CardTransportInfo from '../../Components/Organisations/Shared/CardTransportInfo';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  heroUnit: {
    paddingTop: 56,
    paddingBottom: theme.spacing(3),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      paddingTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: theme.spacing(7),
  },
  subHeroLayout: {
    paddingTop: '0px',
    width: '100%',
    // height: '95px',
    backgroundColor: theme.palette.grey[200],
  },
  searchBar: {
    marginTop: 0,
  },
  organisation_subtitle: {
    marginBottom: theme.spacing(4),
    minHeight: '55px',
  },
  avatar: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  show_all: {
    marginBottom: theme.spacing(5),
  },
  show_more_card: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
}));

const MIN_SAP_HOURS = 48;

const SearchResult = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { city_slug: citySlug } = match.params;
  const organisations = useSelector(state => state.organisationsLeague.organisations);
  const isFetchingOrganisations = useSelector(
    state => state.organisationsLeague.isFetching.getLeagueOrganisations,
  );
  const form = useSelector(state => state.users.form);
  const transportDate = `${moment(form?.transportDate).format('YYYY-MM-DD')} ${moment(
    form?.transportTime,
  )?.format('H:mm:ss')}`;
  const time = moment(transportDate);
  const day = time.isoWeekday() - 1;
  const [tripInfo, setTripInfo] = React.useState(null);

  React.useEffect(() => {
    getTripInfo(form?.addressFrom, form?.addressTo, false).then(data => setTripInfo(data));
  }, [form?.addressFrom, form?.addressTo]);

  React.useEffect(() => {
    dispatch(
      OrganisationsLeagueActions.getLeagueOrganisationsRequest(Routes.currentLeague().id, citySlug),
    );
  }, [dispatch, citySlug]);

  const getOpenedAndClosedOrgas = orgas => {
    const opened = [];
    const closed = [];

    if (orgas) {
      orgas.forEach(orga => {
        const orgaServive = orga.services.find(
          os => os.service.service_category.slug === 'transport',
        );
        const openedAt = formatHours(orgaServive.service.opened_at);
        if (openedAt && openedAt.length > 0) {
          let open = false;
          if (openedAt[day]) {
            openedAt[day].forEach(hours => {
              if (!isOutOfTimeRange(time, hours.start, hours.end)) {
                open = true;
              }
            });
          }
          if (open) opened.push(orga);
          else closed.push(orga);
        } else if (day < 5 && !isOutOfTimeRange(time, DEFAULT_HOUR_START, DEFAULT_HOUR_END)) {
          // default: opened from monday to friday from 09:00 to 18:00
          opened.push(orga);
        } else {
          closed.push(orga);
        }
      });
    }

    return [opened, closed];
  };

  const checkTransportDateIsTooEarly = () => {
    if (!transportDate) return false;
    const now = new Date();
    const transport = new Date(transportDate);

    let diff = moment(transport).diff(now, 'hours', true);

    if (diff < MIN_SAP_HOURS) return true; // too early if less than 48 hours
    if (diff > 4 * 24) return false; // OK if more than 4 days

    const nowDay = now.getDay();
    const transportDay = transport.getDay();

    if (nowDay === 0 || nowDay === 6) return transportDay < 3; // if today is saturday or sunday, transport day must be on wednesday or further

    if (transportDay === 0 || transportDay === 1 || transportDay === 6) return nowDay > 3; // if transport is saturday, sunday or monday, today must be wednesday of before

    if (nowDay > transportDay) return diff - 48 < MIN_SAP_HOURS; // remove week end hours to check if there is more than 48 hours

    return false;
  };

  const [organisationsTransport, allSolidaries, allSaps, allCabs, allAmbulances] =
    React.useMemo(() => {
      if (!organisations) return [[], [], [], [], []];

      const transports = _uniqBy(
        organisations.filter(orga => hasTransport(orga)),
        'id',
      );
      const tooEarlyForSap = checkTransportDateIsTooEarly() ? 'unavailable' : null;

      let [oSol, cSol] = getOpenedAndClosedOrgas(transports.filter(orga => isAssociation(orga)));
      let [oSap, cSap] = getOpenedAndClosedOrgas(transports.filter(orga => isSap(orga)));
      let [oCab, cCab] = getOpenedAndClosedOrgas(
        transports.filter(
          orga =>
            isOther(orga) &&
            (orga.slug === 'taxi-pays-de-mormal' || orga.slug === 'taxi-nord-avesnois'),
        ),
      );
      let [oAmb, cAmb] = getOpenedAndClosedOrgas(
        transports.filter(orga => orga.slug === 'ambulance-pays-de-mormal'),
      );

      oSol = oSol.map(sol => ({ ...sol, disabledMode: tooEarlyForSap ? 'unavailable' : null }));
      cSol = cSol.map(sol => ({ ...sol, disabledMode: 'closed' }));
      const solidaries = oSol.concat(cSol);
      oSap = oSap.map(sap => ({ ...sap, disabledMode: tooEarlyForSap ? 'unavailable' : null }));
      cSap = cSap.map(sap => ({ ...sap, disabledMode: 'closed' }));
      const saps = oSap.concat(cSap);
      oCab = oCab.map(cab => ({ ...cab, disabledMode: null }));
      cCab = cCab.map(cab => ({ ...cab, disabledMode: 'closed' }));
      const cabs = oCab.concat(cCab);
      oAmb = oAmb.map(amb => ({ ...amb, disabledMode: null }));
      cAmb = cAmb.map(amb => ({ ...amb, disabledMode: 'closed' }));
      const ambs = oAmb.concat(cAmb);

      return [transports, solidaries, saps, cabs, ambs];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations, form]);

  const renderCard = (organisation, transportType) => (
    <Grid item md={4} sm={6} xs={12} key={`grid-item-${organisation.id}`}>
      <CardOrganisationTransport
        key={`organisation-${organisation.id}`}
        organisation={organisation}
        bannerUrl={organisation.banner_url}
        avatarUrl={organisation?.holding.cover_url || organisation.banner_url}
        city={organisation.locality}
        name={organisation.name}
        holdingName={organisation?.holding.name || ''}
        description={organisation.holding?.short_description?.replace(/<[^>]*>/g, '') || ''}
        disabled={organisation.disabledMode}
        addressFrom={form?.addressFrom}
        addressTo={form?.addressTo}
        tripInfo={tripInfo}
        transportType={transportType}
      />
    </Grid>
  );

  const renderShowMore = () => (
    <Grid item md={3} sm={6} xs={12} className={classes.show_more_card}>
      <Card itemScope onClick={() => window.open('https://www.passpass.fr', '_blank')}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Pass Pass"
            height="180"
            image={Images.mormal.passpass}
            title="Pass Pass"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              Pass Pass | Tous vos déplacements dans le Nord et le Pas-de-Calais
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              La Centrale Pass Pass, l&apos;outil de la mobilité dans le Nord et dans le
              Pas-de-Calais.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return (
    <>
      <Helmet>
        {Seo.title(
          `${t(`${Routes.currentNamespace()}.SEO_SEARCH_RESULT_PAGE.TRANSPORT.TITLE`)}${t(
            `${Routes.currentNamespace()}.SEO.SUFFIX`,
          )}`,
        )}
      </Helmet>
      {/* <Hidden only={['xs']}> */}
      <Grid container className={classes.container}>
        <Grid item className={classes.subHeroLayout}>
          <Grid item className={classes.layout} style={{ paddingTop: 0 }}>
            <SearchBar
              className={classes.searchBar}
              addressFrom={form?.addressFrom}
              addressTo={form?.addressTo}
              transportDate={form?.transportDate}
              transportTime={form?.transportTime}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* </Hidden> */}
      {isFetchingOrganisations && <LinearProgress className={classes.progress} />}

      <div className={classes.layout}>
        {/* <div className={classes.heroUnit}>
          <Typography
            variant="h3"
            component="h1"
            className={classes.bold}
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t('MORMAL.SEARCH_RESULT_PAGE.TITLE', { cityName: getCityName() }),
            }}
          />
        </div> */}
        <Grid container spacing={2}>
          {(organisationsTransport || []).length === 0 && (
            <EmptyList id="MORMAL.SEARCH_RESULT_PAGE.EMPTY" level="info" />
          )}
          <Grid item md={3} sm={3} xs={12} key={`grid-item`}>
            {tripInfo && (
              <CardTransportInfo
                tripInfo={tripInfo}
                addressFrom={form?.addressFrom}
                addressTo={form?.addressTo}
              />
            )}
          </Grid>
          <Grid item md={9} sm={9} xs={12} key={`grid-item-orgas`}>
            <Grid container spacing={2}>
              {allSolidaries.map(orga => renderCard(orga, 'solidary'))}
              {allSaps.map(orga => renderCard(orga, 'sap'))}
              {allCabs.map(orga => renderCard(orga, 'cab'))}
              {allAmbulances.map(orga => renderCard(orga, 'ambulance'))}
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{ borderColor: 'transparent', margin: '40px 0', backgroundColor: 'transparent' }}
        />
        <Typography
          variant="h4"
          className={classes.bold}
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: t('MORMAL.SEARCH_RESULT_PAGE.OTHERS_TITLE'),
          }}
        />
        {renderShowMore()}
      </div>
      <Footer />
    </>
  );
};

export default SearchResult;
