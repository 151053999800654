import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import TextField from '@material-ui/core/TextField';

import { Images } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import UserActions from '../../Redux/UsersRedux';
import ToasterActions from '../../Redux/ToasterRedux';

import { askTransport, MORMAL_ZONES } from './MormalHelper';
import AutoCompletePlaces from '../../Components/Shared/AutoCompletePlaces';

type Props = {
  history: Object,
  classes: Object,
  className: Object,
  addressFrom: Object,
  addressTo: Object,
  transportDate: Object,
  transportTime: String,
  upsertForm: Function,
  showToaster: Function,
  t: Function,
};

const styles = theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '2rem 3rem 3rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundImage: `url(${Images.mormal.jumbo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '10rem 0',
  },
  heroForm: {
    marginTop: '1.5em',
  },
  places: {
    backgroundColor: '#fff',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  time: {
    backgroundColor: '#fff',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  timeError: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    border: '1px solid #F44335',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  autocomplete: {
    alignSelf: 'center',
    marginTop: theme.spacing(1),
    zIndex: '1000',
  },
});

class SearchBar extends React.Component<Props> {
  constructor(props) {
    super(props);
    const defaultDate = moment().add(3, 'hour').startOf('hour');
    const customState = {
      addressFrom: props.addressFrom,
      addressTo: props.addressTo,
      transportDate: props.transportDate,
      transportTime: props.transportTime,
    };
    this.state = _.defaults(customState, {
      addressFrom: {},
      addressTo: {},
      transportDate: defaultDate,
      transportTime: defaultDate,
      fromError: null,
      toError: null,
      dateError: null,
    });
  }

  handleChangeDate = name => date => {
    this.setState({
      [name]: date,
      dateError: null,
    });
  };

  handleSubmit = () => {
    const { history, upsertForm, showToaster, t } = this.props;
    const { addressTo, addressFrom, transportDate, transportTime } = this.state;

    const transportDateTime = `${moment(transportDate).format('YYYY-MM-DD')} ${moment(
      transportTime,
    )?.format('H:mm:ss')}`;

    if (moment(transportDateTime).diff(new Date(), 'hours', true) < 2) {
      showToaster(t('APP.DATE.ERRORS'));
      this.setState({ dateError: t('APP.DATE.ERRORS') });
      return;
    }

    if (!addressFrom || !addressFrom.latitude || !addressFrom.longitude) {
      showToaster(t('APP.CITY_SELECTION.START.ERRORS'));
      return;
    }

    if (!addressTo || !addressTo.latitude || !addressTo.longitude) {
      showToaster(t('APP.CITY_SELECTION.END.ERRORS'));
      return;
    }

    const handleShowToaster = () => showToaster(t('APP.CITY.ERRORS'));

    askTransport(
      addressFrom,
      addressTo,
      transportDate,
      transportTime,
      upsertForm,
      handleShowToaster,
      slug => history.push(`/leagues/7/cities/${slug}/transport`),
    );

    this.setState({
      dateError: null,
    });
  };

  render() {
    const { className, classes } = this.props;
    const { addressTo, addressFrom, transportDate, transportTime, dateError } = this.state;

    return (
      <Grid container spacing={2} className={className || classes.heroForm}>
        <Grid item sm={1} xs={12} />
        <Grid
          item
          sm={3}
          xs={12}
          className={classes.autocomplete}
          style={{ justifyContent: 'left', zIndex: '1002' }}
        >
          <AutoCompletePlaces
            onSelect={value => {
              this.setState({
                addressFrom: {
                  formattedAddress: value.formatted_address,
                  latitude: value.coordinates?.lat,
                  longitude: value.coordinates?.lng,
                  locality: value.locality,
                  postcode: value.postcode,
                },
              });
            }}
            onClear={() => {
              this.setState({
                addressFrom: {},
              });
            }}
            placeHolder="Départ"
            id="startAddress"
            geographicLimits={MORMAL_ZONES.start}
            cssClass="mapbox-autocomplete-searchBar-mormal"
            defaultValue={addressFrom.formattedAddress}
          />
        </Grid>
        <Grid item sm={3} xs={12} className={classes.autocomplete}>
          <AutoCompletePlaces
            onSelect={value => {
              this.setState({
                addressTo: {
                  formattedAddress: value.formatted_address,
                  latitude: value.coordinates?.lat,
                  longitude: value.coordinates?.lng,
                  locality: value.locality,
                  postcode: value.postcode,
                },
              });
            }}
            onClear={() => {
              this.setState({
                addressTo: {},
              });
            }}
            placeHolder="Destination"
            id="endAddress"
            geographicLimits={MORMAL_ZONES.end}
            cssClass="mapbox-autocomplete-searchBar-mormal"
            defaultValue={addressTo.formattedAddress}
            countries="fr,gp,mq,re,bl,gy,nc,pm,wf,mu,pf,yt,mf,be"
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          {/* date and time */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={transportDate}
              onChange={this.handleChangeDate('transportDate')}
              label="Date de départ"
              disablePast
              cancelText="Annuler"
              renderInput={params => (
                <TextField
                  {...params}
                  variant="filled"
                  required
                  margin="normal"
                  error={dateError !== null}
                  className={classes.places}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              // ampm={false}
              value={transportTime}
              onChange={this.handleChangeDate('transportTime')}
              label="Heure de départ"
              cancelText="Annuler"
              minutesStep={5}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="filled"
                  required
                  margin="normal"
                  error={dateError !== null}
                  className={classes.places}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={1} xs={12}>
          <Fab color="primary" className={classes.submitButton} onClick={this.handleSubmit}>
            GO
          </Fab>
        </Grid>
        <Grid item />
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
  upsertForm: form => dispatch(UserActions.upsertForm(form)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(withTranslation()(SearchBar))));
