import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Routes from '../../../Routing/Routes';

type Props = {
  lead: Object,
  setLead: Function,
  activeServiceKey: String,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formLegend: {
    color: 'black',
  },
}));

const QuotationDefault = ({ lead, setLead, activeServiceKey }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <FormLabel component="legend" className={classes.formLegend}>
          {t('APP.QUOTATION.SERVICES.TITLE')}
        </FormLabel>
        <TextField
          name="description"
          value={lead.description}
          onChange={event => setLead({ ...lead, description: event.target.value })}
          label={t(
            `APP.QUOTATION.DESCRIPTION.${activeServiceKey}${Routes.currentNamespace()}.PLACEHOLDER`,
            t(`APP.QUOTATION.DESCRIPTION.DEFAULT.PLACEHOLDER`),
          )}
          margin="normal"
          variant="outlined"
          multiline
          rows={2}
          fullWidth
        />
      </FormControl>

      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <FormLabel component="legend" className={classes.formLegend}>
          {t('APP.QUOTATION.RECURRENCE')}
        </FormLabel>

        <TextField
          name="recurrence"
          value={lead.recurrence}
          onChange={event => setLead({ ...lead, recurrence: event.target.value })}
          label={t('APP.QUOTATION.RECURRENCE.PLACEHOLDER')}
          margin="normal"
          variant="outlined"
          multiline
          minRows={2}
          fullWidth
        />
      </FormControl>
    </>
  );
};

export default QuotationDefault;
