import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';

import { makeStyles } from '@material-ui/core/styles';
import SectionContent from './SectionContent';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem 4rem',
  },
  initiativeLayout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 0rem 2rem 0rem',
    justifyContent: 'center',
  },
  initiative: {
    display: 'flex !important',
    margin: 'auto',
    '&>*': {
      margin: '2rem',
      [theme.breakpoints.only('xs')]: {
        margin: 0,
      },
    },
  },
  subTitle: {
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  title: String,
  subtitle: String,
  initiatives: Array,
};

function SectionCarousel(props: Props) {
  const classes = useStyles();
  const { title, subtitle, initiatives } = props;

  const settings = {
    arrows: !isMobile,
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 1,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid className={classes.layout}>
      <Typography variant="h3" component="h3" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center" className={classes.subTitle} paragraph>
        {subtitle}
      </Typography>
      <Grid className={classes.initiativeLayout}>
        <Slider {...settings}>
          {initiatives.map(initiative => (
            <Grid key={initiative.title} item xs={11} className={classes.initiative}>
              <SectionContent
                title={initiative.title}
                h3
                fontWeight="600"
                subtitle={initiative.subtitle}
                paragraph={initiative.paragraph}
                image={initiative.image}
                imageOnLeft
                buttonLabel={initiative.buttonLabel}
                buttonLink={initiative.buttonLink}
                targetBlank
              />
            </Grid>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
}
export default SectionCarousel;
