import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import theme from '../../../Themes/muiTheme';

type Props = {
  highlight: Function,
  attribute: String,
  hit: Object,
};

const Highlight = ({ highlight, attribute, hit }: Props) => {
  if (!highlight) return null;

  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <>
      {parsedHit.map(part =>
        part.isHighlighted ? (
          <span
            style={{
              color: theme.mormal.primary.light,
              fontWeight: 'bold',
              textDecoration: 'underline',
              fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
            }}
          >
            {part.value}
          </span>
        ) : (
          <span
            style={{
              fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
            }}
          >
            {part.value}
          </span>
        ),
      )}
    </>
  );
};

const CustomHighlight = connectHighlight(Highlight);

export default CustomHighlight;
