import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Routes from '../../../Routing/Routes';
import SLACallMeBackControls from '../../Shared/SLACallMeBackControls';
import { Grid } from '@material-ui/core';

type Props = {
  lead: Object,
  setLead: Function,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formLegend: {
    color: 'black',
  },
}));

const QuotationOther = ({ lead, setLead }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSLA = Routes.currentLeague().namespace === 'SLA';

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          name="description"
          value={lead.description}
          onChange={event => setLead({ ...lead, description: event.target.value })}
          label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.NEEDS.LABEL')}
          placeholder={t('APP.ASK_CALL.TEXT_FIELD.LABEL.NEEDS.PLACEHOLDER')}
          margin="normal"
          variant="outlined"
          multiline
          minRows={isSLA ? 2 : 8}
          fullWidth
        />
      </FormControl>
      <Grid container spacing={2}>
        {isSLA && <SLACallMeBackControls formData={lead} setFormData={setLead} />}
      </Grid>
    </>
  );
};

export default QuotationOther;
