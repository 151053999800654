import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import parse from 'autosuggest-highlight/parse';
import automatch from 'autosuggest-highlight/match';
import Grid from '@material-ui/core/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import AutoCompletePlaces from '../../Shared/AutoCompletePlaces';
import { ASK_FOR } from '../../../Services/LeadHelper';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import { isInList, isLongerThan } from '../../../Services/FormHelpers';
import Roles from '../../../Acl/Roles';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getRoleInOrga, isMyFamily } from '../../../Acl/Controls';
import WelloAvatar from '../../Shared/WelloAvatar';

moment.locale(i18n?.language);

type Props = {
  currentUser: Object,
  lead: Object,
  isTransport: Boolean,
  onBackClick: Function,
  onSubmitClick: Function,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  formLegend: {
    color: 'black',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  names: {
    alignSelf: 'center',
  },
  namesText: {
    fontWeight: '700',
    marginLeft: theme.spacing(-2),
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 30,
  },
  buttonLink: {
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  checkbox: { marginTop: theme.spacing(-1) },
  checkboxFormControl: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
  },
}));

const getAskForValue = lead => {
  if (lead.askFor === ASK_FOR.ME) return ASK_FOR.ME;
  if (lead.helpedId) return ASK_FOR.OTHER;
  return 'unexistingHelped';
};

const QuotationStep2 = ({ currentUser, lead, isTransport, onBackClick, onSubmitClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;

  const [stepLead, setStepLead] = React.useState(lead);
  const [askFor, setAskFor] = React.useState(getAskForValue(stepLead));
  const [consent, setConsent] = React.useState(false);

  const canSeePrimaries = CheckRoleRule(
    getRoleInOrga(currentUser, holdingSlug, orgaSlug),
    'primaries:read',
  );
  const primaries = useSelector(state => state.organisationMembership.members);
  const isFetchingPrimaries = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const showPrimaries = canSeePrimaries && !isFetchingPrimaries && primaries?.length > 0;

  const isCreatingLead = useSelector(state => state.leads.isFetching.createQualifiedLead);
  const isCreatingNonQualifiedLead = useSelector(
    state => state.leads.isFetching.createNonQualifiedLead,
  );
  const isCreating = isCreatingLead || isCreatingNonQualifiedLead;

  const [address, setAddress] = React.useState({
    formattedAddress: lead.formattedAddress,
    latitude: lead.latitude,
    longitude: lead.longitude,
    locality: lead.locality,
  });

  const isAskingForOther = askFor === ASK_FOR.OTHER;
  const isAskingForMe = askFor === ASK_FOR.ME;

  const [helpeds, helpedLabel] = React.useMemo(() => {
    let helps = [];
    let label = t('APP.QUOTATION.ASK_FOR_HELPED');
    const families = currentUser?.families?.filter(family => !isMyFamily(family));

    if (families?.length > 0) {
      helps = helps.concat(
        families.map(family => ({
          ...family.admin,
          groupTitle: showPrimaries && t('APP.QUOTATION.STEP_2.HELPED_GROUP.TITLE'),
        })),
      );
    }

    if (showPrimaries) {
      helps = helps.concat(
        primaries.map(primary => ({
          ...primary.helper,
          groupTitle: families?.length > 0 && t('APP.QUOTATION.STEP_2.PRIMARIES_GROUP.TITLE'),
        })),
      );

      if (families?.length > 0) label = t('APP.QUOTATION.ASK_FOR_HELP.HELPED_WITH_PRIMARIES');
      else label = t('APP.QUOTATION.ASK_FOR_HELP.PRIMARIES');
    }

    return [helps, label];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaries, currentUser, canSeePrimaries]);

  React.useEffect(() => {
    if (canSeePrimaries) {
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          holdingSlug,
          orgaSlug,
          Roles.PRIMARY,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, orgaSlug, holdingSlug]);

  React.useEffect(() => {
    if (address) {
      setStepLead({
        ...stepLead,
        formattedAddress: address.formattedAddress,
        latitude: address.latitude,
        longitude: address.longitude,
        locality: address.locality,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleChangeSelectedHelped = helped => {
    if (!helped) return;
    setStepLead({
      ...stepLead,
      gender: helped.gender || 'female',
      helpedFirstName: helped.first_name || '',
      helpedLastName: helped.last_name || '',
      helpedPathology: helped.pathology || '',
      birthday: helped.birthday || null,
      formattedAddress: helped.formatted_address || '',
      latitude: helped.coordinates?.latitude || 0,
      longitude: helped.coordinates?.longitude || 0,
      locality: helped.locality || '',
      addressSupplement: helped.address_supplement || '',
      helpedId: helped.id,
    });
  };

  const handleRadioChange = event => {
    if (event.target.value === ASK_FOR.ME) {
      setStepLead({
        ...stepLead,
        askFor: ASK_FOR.ME,
        gender: currentUser ? currentUser.gender : 'female',
        birthday: currentUser ? currentUser.birthday : null,
        formattedAddress: currentUser ? currentUser.formatted_address : '',
        latitude: currentUser?.coordinates ? currentUser.coordinates.latitude : 0,
        longitude: currentUser?.coordinates ? currentUser.coordinates.longitude : 0,
        locality: currentUser?.locality || '',
        addressSupplement: currentUser?.address_supplement || '',
      });
    } else if (event.target.value === ASK_FOR.OTHER) {
      const helped = helpeds.length > 0 ? helpeds[0] : null;
      setStepLead({
        ...stepLead,
        askFor: ASK_FOR.OTHER,
        gender: helped?.gender || 'female',
        helpedFirstName: helped?.first_name || '',
        helpedLastName: helped?.last_name || '',
        helpedPathology: helped?.pathology || '',
        birthday: helped?.birthday || null,
        formattedAddress: helped?.formatted_address || '',
        latitude: helped?.coordinates ? helped?.coordinates.latitude : 0,
        longitude: helped?.coordinates ? helped?.coordinates.longitude : 0,
        locality: helped?.locality || '',
        addressSupplement: helped?.address_supplement || '',
        helpedId: helped?.id || null,
      });
    } else {
      setStepLead({
        ...stepLead,
        askFor: ASK_FOR.OTHER,
        gender: 'female',
        helpedFirstName: '',
        helpedLastName: '',
        helpedPathology: '',
        birthday: null,
        formattedAddress: '',
        latitude: 0,
        longitude: 0,
        locality: '',
        addressSupplement: '',
        helpedId: null,
      });
    }

    setAskFor(event.target.value);
  };

  const isValidFormStep = () =>
    isInList([ASK_FOR.ME, ASK_FOR.OTHER])(stepLead.askFor) &&
    (null !== stepLead.birthday || !isTransport) &&
    (isLongerThan(0)(stepLead.formattedAddress) || isTransport) &&
    ((isAskingForMe && isLongerThan(0)(stepLead.firstName) && isLongerThan(0)(stepLead.lastName)) ||
      (stepLead.askFor === ASK_FOR.OTHER &&
        isLongerThan(0)(stepLead.helpedFirstName) &&
        isLongerThan(0)(stepLead.helpedLastName) &&
        consent));

  return (
    <>
      <Typography variant="h5" align="center" className={classes.formTitle}>
        {t('APP.QUOTATION.STEP_2.TITLE')}
      </Typography>
      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <RadioGroup
          aria-label="askFor"
          name="askFor"
          className={classes.group}
          value={askFor}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="me"
            control={<Radio color="primary" />}
            label={t('APP.QUOTATION.ASK_FOR_ME')}
          />
          {currentUser && (
            <FormControlLabel
              value="other"
              control={<Radio color="primary" />}
              label={helpedLabel}
            />
          )}
          {isAskingForOther && helpeds?.length > 0 && (
            <>
              <Autocomplete
                fullWidth
                id="primary-select"
                options={helpeds}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                style={{ paddingTop: 8, paddingBottom: 8 }}
                defaultValue={helpeds[0]}
                groupBy={member => member.groupTitle}
                getOptionLabel={member => `${member.first_name} ${member.last_name}`}
                renderOption={(member, { inputValue }) => {
                  const matches = automatch(`${member.first_name} ${member.last_name}`, inputValue);
                  const parts = parse(`${member.first_name} ${member.last_name}`, matches);
                  return (
                    <Grid container className={classes.text}>
                      <Grid item xs={12} sm={1} className={classes.names}>
                        <WelloAvatar
                          avatarUrl={member.avatar_url}
                          firstName={member.first_name}
                          lastName={member.last_name}
                          backgroundColor={member.avatar_background_color}
                          size={64}
                          avatarStyle={{ height: 24, width: 24, fontSize: '0.8rem' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={11} className={classes.names}>
                        <Typography className={classes.namesText}>
                          {parts.map((part, index) => (
                            // eslint-disable-next-line
                            <span
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${member.id}-${index}`}
                              style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('APP.QUOTATION.STEP_2.AUTOCOMPLETE.TITLE')}
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => handleChangeSelectedHelped(value)}
              />
              {canSeePrimaries && (
                <Grid align="Left">
                  <Typography>{t('APP.QUOTATION.NO_PRIMARY')}</Typography>
                  <Button
                    className={classes.buttonLink}
                    color="primary"
                    onClick={() => history.push(`/${holdingSlug}/${orgaSlug}/primaries`)}
                  >
                    {t('APP.QUOTATION.ADD.PRIMARY')}
                  </Button>
                </Grid>
              )}
            </>
          )}
          {isAskingForOther && helpeds?.length < 1 && (
            <Grid align="Left">
              <Typography>{t('APP.QUOTATION.NO_HELPED')}</Typography>
              <Button
                className={classes.buttonLink}
                color="primary"
                onClick={() => history.push(`/families/${currentUser?.family_slug}`)}
              >
                {t('APP.QUOTATION.ADD.HELPED')}
              </Button>
            </Grid>
          )}
          {!currentUser && (
            <FormControlLabel
              value="unexistingHelped"
              control={<Radio color="primary" />}
              label={t('APP.QUOTATION.ASK_FOR_HELP.UNEXISTING_HELPED')}
            />
          )}
        </RadioGroup>
      </FormControl>

      {!(isAskingForOther && helpeds?.length < 1) && (
        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.formLegend}>
            {t(isAskingForMe ? 'APP.QUOTATION.YOUR_INFO' : 'APP.QUOTATION.THEIR_INFO')}
          </FormLabel>

          <RadioGroup
            aria-label="gender"
            name="gender"
            className={classes.group}
            value={stepLead.gender}
            onChange={event => setStepLead({ ...stepLead, gender: event.target.value })}
            row
          >
            <FormControlLabel
              value="female"
              control={<Radio color="primary" />}
              label={t('FEMALE')}
            />
            <FormControlLabel value="male" control={<Radio color="primary" />} label={t('MALE')} />
          </RadioGroup>

          <TextField
            name={isAskingForMe ? 'firstName' : 'helpedFirstName'}
            required
            value={isAskingForMe ? stepLead.firstName : stepLead.helpedFirstName}
            onChange={event =>
              isAskingForMe
                ? setStepLead({ ...stepLead, firstName: event.target.value })
                : setStepLead({ ...stepLead, helpedFirstName: event.target.value })
            }
            label={t(
              isAskingForMe ? 'APP.QUOTATION.YOUR_FIRST_NAME' : 'APP.QUOTATION.THEIR_FIRST_NAME',
            )}
            margin="normal"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isAskingForOther}
          />

          <TextField
            name={isAskingForMe ? 'lastName' : 'helpedLastName'}
            required
            value={isAskingForMe ? stepLead.lastName : stepLead.helpedLastName}
            onChange={event =>
              isAskingForMe
                ? setStepLead({ ...stepLead, lastName: event.target.value })
                : setStepLead({ ...stepLead, helpedLastName: event.target.value })
            }
            label={t(
              isAskingForMe ? 'APP.QUOTATION.YOUR_LAST_NAME' : 'APP.QUOTATION.THEIR_LAST_NAME',
            )}
            margin="normal"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isAskingForOther}
          />

          {!isTransport && (
            <>
              <FormControl margin="normal" component="fieldset" fullWidth required>
                <InputLabel
                  variant="outlined"
                  shrink
                  className={classes.selectLabel}
                  style={{
                    zIndex: '1011',
                  }}
                >
                  {t(isAskingForMe ? 'APP.QUOTATION.YOUR_ADDRESS' : 'APP.QUOTATION.THEIR_ADDRESS')}
                </InputLabel>
                <AutoCompletePlaces
                  onSelect={value => {
                    setAddress({
                      formattedAddress: value.formatted_address,
                      latitude: value.coordinates?.lat,
                      longitude: value.coordinates?.lng,
                      locality: value.locality,
                    });
                  }}
                  onClear={() => {
                    setAddress({
                      formattedAddress: '',
                      latitude: null,
                      longitude: null,
                      locality: '',
                    });
                  }}
                  placeHolder={t('APP.ACTIVITY.ADDRESS.PLACE_HOLDER')}
                  defaultValue={stepLead.formattedAddress}
                  id="helperAddress"
                />
              </FormControl>

              <TextField
                name="addressSupplement"
                value={stepLead.addressSupplement}
                onChange={event => {
                  setStepLead({ ...stepLead, addressSupplement: event.target.value });
                }}
                label={t('APP.USER.TEXT_FIELD.ADDRESS_SUPPLEMENT')}
                placeholder={t('APP.USER.TEXT_FIELD.ADDRESS_SUPPLEMENT.PLACE_HOLDER')}
                margin="normal"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={stepLead.birthday}
              onChange={date => setStepLead({ ...stepLead, birthday: date })}
              label={t(
                isAskingForMe ? 'APP.QUOTATION.YOUR_BIRTH_DATE' : 'APP.QUOTATION.THEIR_BIRTH_DATE',
              )}
              cancelText={t('CANCEL')}
              renderInput={params => (
                <TextField {...params} variant="outlined" required margin="normal" />
              )}
            />
          </LocalizationProvider>

          <TextField
            name={isAskingForMe ? 'helperPathology' : 'helpedPathology'}
            value={isAskingForMe ? stepLead.helperPathology : stepLead.helpedPathology}
            onChange={event =>
              isAskingForMe
                ? setStepLead({ ...stepLead, helperPathology: event.target.value })
                : setStepLead({ ...stepLead, helpedPathology: event.target.value })
            }
            label={t('APP.QUOTATION.INFO.LABEL')}
            placeholder={t('APP.QUOTATION.INFO.PLACE_HOLDER')}
            margin="normal"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          {!isAskingForMe && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={event => setConsent(event.target.checked)}
                  checked={consent}
                  color="primary"
                  className={classes.checkbox}
                />
              }
              label={t(
                isAskingForOther
                  ? 'APP.QUOTATION.CONSENT.PRIMARY_OR_HELPED'
                  : 'APP.QUOTATION.CONSENT',
              )}
              className={classes.checkboxFormControl}
            />
          )}
        </FormControl>
      )}
      <div className={classes.buttonContainer}>
        <Button onClick={() => onBackClick(stepLead)} className={classes.backButton}>
          {t('BACK')}
        </Button>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={isCreating || !isValidFormStep()}
            onClick={event => onSubmitClick(event, stepLead)}
          >
            {!isCreating && t('SEND')}
            {isCreating && <CircularProgress size={24} />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default QuotationStep2;
