import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import 'moment/locale/fr';

import { isAttributeDefined, isInList, isLongerThan } from '../../../Services/FormHelpers';
import { GENDER } from '../../../Services/LeadHelper';
import { checkEmailValidity } from '../../../Services/DataHelper';
import PhoneControl from '../../Shared/PhoneControl';
import { CircularProgress } from '@material-ui/core';

type Props = {
  lead: Object,
  currentUser: Object,
  onBackClick: Function,
  onNextClick: Function,
  onSubmitClick: Function,
  isOtherCategory: Boolean,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

const QuotationStep1 = ({
  lead,
  currentUser,
  onBackClick,
  onNextClick,
  onSubmitClick,
  isOtherCategory,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [stepLead, setStepLead] = React.useState(lead);
  const [emailError, setEmailError] = React.useState(null);
  const isCreatingLead = useSelector(state => state.leads.isFetching.createQualifiedLead);
  const isCreatingNonQualifiedLead = useSelector(
    state => state.leads.isFetching.createNonQualifiedLead,
  );
  const isCreating = isCreatingLead || isCreatingNonQualifiedLead;

  const isValidFormStep = () => {
    return (
      isLongerThan(0)(stepLead.phone) &&
      stepLead.phoneError === null &&
      isInList([GENDER.MALE, GENDER.FEMALE])(stepLead.gender) &&
      isLongerThan(0)(stepLead.firstName) &&
      isLongerThan(0)(stepLead.lastName) &&
      (!isLongerThan(0)(stepLead.email) || checkEmailValidity(stepLead.email))
    );
  };

  return (
    <>
      <Typography variant="h5" align="center" className={classes.formTitle}>
        {t('APP.QUOTATION.STEP_1.TITLE')}
      </Typography>
      <RadioGroup
        aria-label="gender"
        name="gender"
        className={classes.group}
        value={stepLead.gender}
        onChange={event => setStepLead({ ...stepLead, gender: event.target.value })}
        row
      >
        <FormControlLabel
          value="female"
          control={<Radio color="primary" />}
          label={t('FEMALE')}
          disabled={isAttributeDefined('gender')(currentUser)}
        />
        <FormControlLabel
          value="male"
          control={<Radio color="primary" />}
          label={t('MALE')}
          disabled={isAttributeDefined('gender')(currentUser)}
        />
      </RadioGroup>
      <TextField
        name="firstName"
        value={stepLead.firstName}
        onChange={event => setStepLead({ ...stepLead, firstName: event.target.value })}
        label={t('APP.QUOTATION.YOUR_FIRST_NAME')}
        margin="normal"
        variant="outlined"
        fullWidth
        disabled={isAttributeDefined('first_name')(currentUser)}
        required
      />
      <TextField
        name="lastName"
        value={stepLead.lastName}
        onChange={event => setStepLead({ ...stepLead, lastName: event.target.value })}
        label={t('APP.QUOTATION.YOUR_LAST_NAME')}
        margin="normal"
        variant="outlined"
        fullWidth
        disabled={isAttributeDefined('last_name')(currentUser)}
        required
      />
      <PhoneControl
        phone={stepLead.phone}
        phoneError={stepLead.phoneError}
        countryCode={stepLead.countryCode}
        setPhone={p => setStepLead({ ...stepLead, phone: p })}
        setPhoneError={error => setStepLead({ ...stepLead, phoneError: error })}
        setCountryCode={c => setStepLead({ ...stepLead, countryCode: c })}
        readOnly={isAttributeDefined('phone')(currentUser)}
        required
      />
      <TextField
        name="email"
        value={stepLead.email}
        onChange={event => setStepLead({ ...stepLead, email: event.target.value?.trim() })}
        label={t('APP.QUOTATION.EMAIL')}
        margin="normal"
        disabled={isAttributeDefined('email')(currentUser)}
        variant="outlined"
        fullWidth
        onBlur={() => setEmailError(!checkEmailValidity(stepLead.email))}
        onFocus={() => setEmailError(false)}
        error={isLongerThan(0)(stepLead.email) && emailError}
        helperText={isLongerThan(0)(stepLead.email) && emailError && t('INCORRECT_EMAIL')}
      />

      <div className={classes.buttonContainer}>
        <Button onClick={() => onBackClick(stepLead)} className={classes.backButton}>
          {t('BACK')}
        </Button>
        {isOtherCategory ? (
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={isCreating || !isValidFormStep()}
            onClick={event => onSubmitClick(event, stepLead)}
          >
            {isCreating ? <CircularProgress size={24} /> : t('SEND')}
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={!isValidFormStep()}
            color="primary"
            onClick={() => onNextClick(stepLead)}
          >
            {t('NEXT')}
          </Button>
        )}
      </div>
    </>
  );
};

export default QuotationStep1;
