import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useRouteMatch, useLocation } from 'react-router-dom';
import 'moment/locale/fr';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../Components/Layout/Footer';
import { muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../Routing/Routes';

import Seo from '../../Routing/Seo';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';

import {
  CustomHitsOrganisation,
  CustomHitsArticle,
  CustomHitsTransport,
  CustomHitsCourse,
  CustomHitsTwig,
} from '../../Components/Shared/Algolia/CustomHits';

import {
  INDEX_NAMES,
  FILTERING_ATTRIBUTES,
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  LEAGUE_SLUG,
  GEOGRAPHIC_LIMITS,
  formatType,
  wordifyType,
} from '../../Services/AlgoliaHelper';
import InstantSearchResults from '../../Components/Shared/Algolia/InstantSearchResults';

const useStyles = makeStyles(theme => ({
  activityUnit: {
    paddingBottom: theme.spacing(3),
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  subHeroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundColor: theme.global.secondary.main,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: '60%',
    padding: '3rem 0',
  },
  headingContainer: {
    width: '100%',
    padding: '3rem 0 3rem',
  },
  searchBar: {
    marginTop: 0,
  },
  results: {
    minHeight: '60vh',
  },
}));

function SearchResult() {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    params: { type_slug: typeSlug },
  } = match;
  const type = formatType(typeSlug);
  const typeName = wordifyType(typeSlug);
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    serviceSlug: queryParam.get('service') || '',
    city: queryParam.get('city') || null,
    address: queryParam.get('address') || '',
    latitude: queryParam.get('lat') || null,
    longitude: queryParam.get('long') || null,
  };
  const position = urlParams.latitude != null && urlParams.longitude != null;

  const typeAttributes = {
    organisations: {
      indexName: INDEX_NAMES.ORGANISATION,
      title: 'APP.SEARCH_RESULT_PAGE.ORGANISATIONS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 15,
        query: urlParams.serviceSlug,
        getRankingInfo: true,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.Global}`,
        // AND ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Association' OR ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Pfr'
      },
      HitsDisplay: CustomHitsOrganisation,
      hasMap: true,
      refinementAttributes: [
        {
          label: 'Types de structure',
          attribute: FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE,
        },
      ],
      hideKeywordField: false,
      hideCityField: false,
    },
    twigs: {
      indexName: INDEX_NAMES.TWIG,
      title: 'APP.SEARCH_RESULT_PAGE.TWIGS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: true,
      configureSettings: {
        hitsPerPage: 12,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.Global}`,
      },
      HitsDisplay: CustomHitsTwig,
      hasMap: true,
      refinementAttributes: [
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
          attribute: FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE,
          limit: 30,
          showMoreLimit: 30,
        },
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.PATHOLOGY'),
          attribute: FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE,
        },
      ],
    },
    // care: {
    //   indexName: INDEX_NAMES.ORGANISATION,
    //   title: 'APP.SEARCH_RESULT_PAGE.ORGANISATIONS',
    //   aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
    //   showCityInfo: true,
    //   showKeywordInfo: false,
    //   configureSettings: {
    //     hitsPerPage: 15,
    //     query: serviceSlug,
    //     getRankingInfo: true,
    //     filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.Global} AND ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Establishment' OR ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Sap' OR ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Healthcare'`,
    //   },
    //   HitsDisplay: CustomHitsOrganisation,
    //   hasMap: true,
    //   refinementAttributes: [
    //     // {
    //     //   label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
    //     //   attribute: FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE,
    //     // },
    //   ],
    //   hideKeywordField: false,
    //   hideCityField: false,
    // },
    transports: {
      indexName: INDEX_NAMES.ORGANISATION,
      title: 'WEELO.SEO_SEARCH_RESULT_PAGE.TRANSPORT.TITLE',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 15,
        query: urlParams.serviceSlug,
        getRankingInfo: true,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.Global} AND ${FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_CATEGORY_SLUG}: transport`,
      },
      HitsDisplay: CustomHitsTransport,
      hasMap: false,
      hideKeywordField: true,
      hideCityField: false,
    },
    articles: {
      indexName: INDEX_NAMES.ARTICLE,
      title: 'GLOBAL.SEARCH_RESULT_PAGE.TITLE.INFO',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.Global}`,
      },
      HitsDisplay: CustomHitsArticle,
      hasMap: false,
      showKeywordInfo: true,
      // refinementAttributes: [
      //   {
      //     label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
      //     attribute: FILTERING_ATTRIBUTES.ARTICLE_EXPERTISE_FACET_ATTRIBUTE,
      //   },
      // ],
    },
    courses: {
      indexName: INDEX_NAMES.COURSE,
      title: 'GLOBAL.SEARCH_RESULT_PAGE.COURSES',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.POSTABLE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.Global}`,
      },
      HitsDisplay: CustomHitsCourse,
      hasMap: false,
      hideKeywordField: false,
      hideCityField: false,
      showKeywordInfo: true,
    },
  };

  return (
    <Grid>
      <Helmet>
        {urlParams.city &&
          ['organisations', 'twigs'].includes(type) &&
          Seo.title(
            `${typeName} à ${urlParams.city}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`,
          )}
        {(!urlParams.city || !['organisations', 'twigs'].includes(type)) &&
          Seo.title(`Résultats ${typeName}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
        {Seo.canonical(
          Routes.currentLeague().id,
          `${location.pathname}${location.search.replaceAll(' ', '%20')}`,
        )}
      </Helmet>
      <Hidden only={['xs']}>
        <Grid container className={classes.container}>
          <Grid item className={classes.subHeroLayout}>
            <Grid item className={classes.layout} style={{ paddingTop: 0 }}>
              <SearchBar
                className={classes.searchBar}
                service={urlParams.serviceSlug}
                address={urlParams.address}
                city={urlParams.city}
                latitude={urlParams.latitude}
                longitude={urlParams.longitude}
                type={type}
                goBackgrounColor={muiTheme.global.primary.main}
                geographicLimits={GEOGRAPHIC_LIMITS.SLA}
                searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.Global}
                defaultType="structures"
                hideKeywordField={typeAttributes[type].hideKeywordField}
                hideCityField={typeAttributes[type].hideCityField}
                placeHolder="Rennes, Ille-et-Vilaine, France"
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Grid className={classes.results}>
        <InstantSearchResults
          request={typeAttributes[type]}
          position={position ? { lat: urlParams.latitude, lng: urlParams.longitude } : null}
          typeAttributes={typeAttributes}
          tabs={[
            {
              label: t('GLOBAL.SEARCH_RESULT_PAGE.TAB.ORGANISATIONS'),
              value: 'organisations',
            },
            // {
            //   label: t('GLOBAL.SEARCH_RESULT_PAGE.TAB.CARE'),
            //   value: 'care',
            // },
            { label: t('GLOBAL.SEARCH_RESULT_PAGE.TAB.TWIGS'), value: 'twigs' },
            { label: t('GLOBAL.SEARCH_RESULT_PAGE.TAB.TRANSPORTS'), value: 'transports' },
            { label: t('GLOBAL.SEARCH_RESULT_PAGE.TAB.INFO'), value: 'articles' },
            { label: t('GLOBAL.SEARCH_RESULT_PAGE.TAB.COURSE'), value: 'courses' },
          ]}
        />
      </Grid>
      <Footer />
    </Grid>
  );
}

export default SearchResult;
