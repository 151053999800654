import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useRouteMatch, useLocation } from 'react-router-dom';
import 'moment/locale/fr';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../Components/Layout/Footer';
import { Images, muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../Routing/Routes';

import Seo from '../../Routing/Seo';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';

import {
  CustomHitsOrganisation,
  CustomHitsTwig,
  CustomHitsArticle,
  CustomHitsCourse,
  CustomClubs,
  CustomHitsCommunity,
} from '../../Components/Shared/Algolia/CustomHits';

import {
  INDEX_NAMES,
  FILTERING_ATTRIBUTES,
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  LEAGUE_SLUG,
  ESTABLISHMENT_SLUG,
  formatType,
  wordifyType,
} from '../../Services/AlgoliaHelper';
import InstantSearchResults from '../../Components/Shared/Algolia/InstantSearchResults';
import FooterSeo from './FooterSeo';
import { getActivityDescription, getCityDescription } from '../../Services/SeoHelper';

const useStyles = makeStyles(theme => ({
  activityUnit: {
    paddingBottom: theme.spacing(3),
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  subHeroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundImage: `url(${Images.sla.jumboSearchResults})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: '60%',
    padding: theme.spacing(3, 0),
  },
  headingContainer: {
    width: '100%',
    padding: '3rem 0 3rem',
  },
  searchBar: {
    marginTop: 0,
  },
  results: {
    minHeight: '60vh',
  },
}));

function SearchResult() {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    params: { type_slug: typeSlug },
  } = match;
  const type = formatType(typeSlug);
  const typeName = wordifyType(typeSlug);
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    serviceSlug: queryParam.get('service') || '',
    city: queryParam.get('city') || null,
    address: queryParam.get('address') || '',
    latitude: queryParam.get('lat') || null,
    longitude: queryParam.get('long') || null,
    bbox: queryParam.get('bbox') || null,
    pathology: queryParam.get(FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE) || '',
    age: queryParam.get('age') || null,
    expertise: queryParam.get(FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE) || null,
  };
  const hasPosition = urlParams.latitude != null && urlParams.longitude != null;
  const bbox = urlParams.bbox?.split(';')?.map(val => parseFloat(val, 10)) || null;
  const getEstablishmentParams = () => {
    let param = '';
    if (urlParams.pathology === 'Maladies neuro-évolutives') {
      param = ESTABLISHMENT_SLUG.PFR_PA;
    } else if (urlParams.age === '60') {
      param = ESTABLISHMENT_SLUG.PFR_PA;
    } else if (
      urlParams.age === '-60' &&
      (urlParams.pathology === 'Maladies chroniques' || urlParams.pathology === 'Handicap')
    ) {
      param = ESTABLISHMENT_SLUG.PFR_PH;
    }
    return param;
  };
  const filterName =
    (urlParams.pathology || urlParams.age) && getEstablishmentParams()
      ? `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SLA} AND ${
          FILTERING_ATTRIBUTES.ORGANISATION_ESTABLISHMENT_TYPE_ATTRIBUTE
        }:${getEstablishmentParams()} OR ${
          FILTERING_ATTRIBUTES.ORGANISATION_ESTABLISHMENT_TYPE_ATTRIBUTE
        }:${ESTABLISHMENT_SLUG.PFR_PA_PH}`
      : `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SLA}`;

  const dateNow = Math.floor(Date.now() / 1000);

  const twigFilterName =
    urlParams.pathology && urlParams.pathology !== 'Autre'
      ? `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SLA} AND ${FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE}:"${urlParams.pathology}"`
      : `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SLA}`;

  const typeAttributes = {
    organisations: {
      indexName: INDEX_NAMES.ORGANISATION,
      title: 'APP.SEARCH_RESULT_PAGE.ORGANISATIONS_PFR',
      titleWithCity: 'APP.SEARCH_RESULT_PAGE.YOUR_ORGANISATION_PFR',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.AT',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 25,
        // query: serviceSlug,
        getRankingInfo: true,
        filters: filterName,
      },
      HitsDisplay: CustomHitsOrganisation,
      hasMap: true,
      refinementAttributes: [
        // {
        //   label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
        //   attribute: FILTERING_ATTRIBUTES.ORGANISATION_SERVICE_LEVEL_ATTRIBUTE,
        // },
      ],
      hideKeywordField: true,
      hideCityField: false,
      showPathologyInfo: true,
      isFilteringEnough: getEstablishmentParams(),
      footerSeo: true,
    },
    twigs: {
      indexName: INDEX_NAMES.TWIG,
      title: 'APP.SEARCH_RESULT_PAGE.TWIGS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 12,
        // query: serviceSlug,
        filters: `${twigFilterName} AND ${FILTERING_ATTRIBUTES.TWIG_DATE_ATTRIBUTE} > ${dateNow}`,
      },
      HitsDisplay: CustomHitsTwig,
      hasMap: true,
      mapZoom: 12,
      refinementAttributes: [
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
          attribute: FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE,
          limit: 30,
          showMoreLimit: 30,
        },
      ],
      hideKeywordField: true,
      hideCityField: false,
      footerSeo: true,
    },
    articles: {
      indexName: INDEX_NAMES.ARTICLE,
      title: 'APP.SEARCH_RESULT_PAGE.ARTICLES',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.SLA}`,
      },
      refinementAttributes: [
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.RELATED_WORDS'),
          attribute: FILTERING_ATTRIBUTES.RELATED_WORDS_ATTRIBUTE,
          limit: 10,
          showMoreLimit: 5,
        },
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.POSTABLE'),
          attribute: FILTERING_ATTRIBUTES.POSTABLE_NAME_ATTRIBUTE,
          limit: 10,
          showMoreLimit: 5,
        },
      ],
      HitsDisplay: CustomHitsArticle,
      hasMap: false,
      showKeywordInfo: true,
      // refinementAttributes: [
      // {
      //   label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
      //   attribute: FILTERING_ATTRIBUTES.ARTICLE_EXPERTISE_FACET_ATTRIBUTE,
      // },
      // ],
      hideKeywordField: false,
      hideCityField: true,
    },
    courses: {
      indexName: INDEX_NAMES.COURSE,
      title: 'SLA.SEARCH_RESULT_PAGE.COURSES',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.SLA}`,
      },
      HitsDisplay: CustomHitsCourse,
      hasMap: false,
      hideKeywordField: false,
      hideCityField: true,
      showKeywordInfo: true,
    },
    clubs: {
      indexName: INDEX_NAMES.COMMUNITY,
      title: 'APP.SEARCH_RESULT_PAGE.CLUBS',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.SLA}`,
      },
      FallBackComponent: CustomClubs,
      HitsDisplay: CustomHitsCommunity,
      hasMap: false,
      hideKeywordField: false,
      hideCityField: true,
    },
  };

  const cityDescription = getCityDescription(Routes.currentNamespace(), type, urlParams.city);
  const activityDescription = getActivityDescription(
    Routes.currentNamespace(),
    urlParams.expertise,
  );

  return (
    <Grid>
      <Helmet>
        {urlParams.city &&
          ['organisations', 'twigs'].includes(type) &&
          Seo.title(
            `${typeName} à ${urlParams.city}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`,
          )}
        {(!urlParams.city || !['organisations', 'twigs'].includes(type)) &&
          Seo.title(`Résultats ${typeName}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
        {Seo.canonical(
          Routes.currentLeague().id,
          `${location.pathname}${location.search.replaceAll(' ', '%20')}`,
        )}
        {cityDescription && type === 'organisations' && Seo.description(cityDescription)}
        {activityDescription && type === 'twigs' && Seo.description(activityDescription)}
      </Helmet>
      <Hidden only={['xs']}>
        <Grid container className={classes.container}>
          <Grid item className={classes.subHeroLayout}>
            <Grid item className={classes.layout} style={{ paddingTop: 0 }}>
              <SearchBar
                className={classes.searchBar}
                service={urlParams.serviceSlug}
                address={urlParams.address}
                city={urlParams.city}
                latitude={urlParams.latitude}
                longitude={urlParams.longitude}
                bbox={bbox}
                type={type}
                goBackgrounColor={muiTheme.sla.primary.main}
                searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.SLA}
                defaultType="structures"
                showPathologiesFilter
                pathologyParam={urlParams.pathology}
                ageParam={urlParams.age}
                hideKeywordField={typeAttributes[type].hideKeywordField}
                hideCityField={typeAttributes[type].hideCityField}
                AutoCompleteWidth={7}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Grid className={classes.results}>
        <InstantSearchResults
          request={typeAttributes[type]}
          position={hasPosition ? { lat: urlParams.latitude, lng: urlParams.longitude } : null}
          bbox={bbox}
          typeAttributes={typeAttributes}
          tabs={[
            {
              label: t('APP.SEARCH_RESULT_PAGE.TAB.ORGANISATIONS'),
              value: 'organisations',
            },
            { label: t('APP.SEARCH_RESULT_PAGE.TAB.TWIGS'), value: 'twigs' },
            {
              label: t('APP.SEARCH_RESULT_PAGE.TAB.ARTICLES'),
              value: 'articles',
            },
            { label: t('APP.SEARCH_RESULT_PAGE.TAB.CLUBS'), value: 'clubs' },
            {
              label: t('SLA.SEARCH_RESULT_PAGE.TAB.COURSES'),
              value: 'courses',
            },
          ]}
          showHighlightedOrga={bbox}
        />
      </Grid>
      {typeAttributes[type].footerSeo && <FooterSeo resultsType={typeAttributes[type].indexName} />}
      <Footer />
    </Grid>
  );
}

export default SearchResult;
