import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isServiceTransport } from '../../Acl/Controls';
import { isClientSide } from '../../Config/ServerConfig';
import { stringToCoordinatesArray } from '../../Services/DataHelper';

type Props = {
  lead: Object,
  height: String,
  isDialog: Boolean,
};

const useStyles = makeStyles(theme => ({
  leafLetContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      height: '200px !important',
    },
  },
}));

const LeadMap = ({ lead, height, isDialog }: Props) => {
  const classes = useStyles();
  const [map, setMap] = React.useState(null);

  if (!isClientSide()) return <></>;

  // eslint-disable-next-line global-require
  const I = require('leaflet');

  const startIcon = I.icon({
    // eslint-disable-next-line global-require
    iconUrl: require('../../Assets/Images/pin-start.svg'),
    // eslint-disable-next-line global-require
    shadowUrl: require('../../Assets/Images/pin-shadow.svg'),
    iconSize: [45, 45], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 45], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 45], // the same for the shadow
    popupAnchor: [50, 50], // point from which the popup should open relative to the iconAnchor
  });

  const endIcon = I.icon({
    // eslint-disable-next-line global-require
    iconUrl: require('../../Assets/Images/pin-end.svg'),
    // eslint-disable-next-line global-require
    shadowUrl: require('../../Assets/Images/pin-shadow.svg'),
    iconSize: [45, 45], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 45], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 45], // the same for the shadow
    popupAnchor: [50, 50], // point from which the popup should open relative to the iconAnchor
  });

  const latitude = lead?.coordinates?.latitude;
  const longitude = lead?.coordinates?.longitude;
  const formattedAddress = lead?.formatted_address;

  const isTransport = isServiceTransport(lead?.service || null);
  if ((isTransport && !lead?.departure_from) || (!isTransport && (!latitude || !longitude))) {
    return <></>;
  }
  const markers = [];
  const formattedAddresses = [];
  if (isTransport && lead?.departure_from) {
    markers.push([lead?.departure_from?.latitude, lead?.departure_from?.longitude]);
    formattedAddresses.push(lead?.meta?.departureFormattedAddress);
    if (
      lead?.departure_from.latitude !== lead?.arrival_to?.latitude ||
      lead?.departure_from.longitude !== lead?.arrival_to?.longitude
    ) {
      markers.push([lead?.arrival_to?.latitude, lead?.arrival_to?.longitude]);
      formattedAddresses.push(lead?.meta?.arrivalFormattedAddress);
    }
  } else {
    markers.push([latitude, longitude]);
    formattedAddresses.push(formattedAddress);
  }
  // eslint-disable-next-line global-require
  const L = require('react-leaflet');
  const bounds = markers.length > 1 ? window.L.latLngBounds(markers) : null;

  const geolocs = isTransport ? stringToCoordinatesArray(lead?.meta?.geolocs, true) : null;

  if (map && markers?.length > 1) {
    map.flyTo([(markers[0][0] + markers[1][0]) / 2, (markers[0][1] + markers[1][1]) / 2]);
  }

  return (
    <L.MapContainer
      bounds={bounds}
      scrollWheelZoom={false}
      center={markers[0]}
      zoom={markers.length > 1 ? null : 13}
      className={!isDialog && classes.leafLetContainer}
      style={{ height: height || '350px' }}
      whenCreated={setMap}
    >
      <L.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {markers[0] && (
        <L.Marker position={markers[0]} icon={startIcon}>
          <L.Popup className={classes.leafLetPopup}>{formattedAddresses[0]}</L.Popup>
        </L.Marker>
      )}
      {markers[1] && (
        <L.Marker position={markers[1]} icon={endIcon}>
          <L.Popup className={classes.leafLetPopup}>{formattedAddresses[1]}</L.Popup>
        </L.Marker>
      )}
      {geolocs && <L.Polyline positions={geolocs} color="blue" weight={2} />}
    </L.MapContainer>
  );
};

export default LeadMap;
