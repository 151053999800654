import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../Layout/Styles/LayoutStyles';
import { BulletPointContainer } from '../Block';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem 4rem',
  },
  item: {
    alignSelf: 'center',
  },
  sectionTitle: {
    fontWeight: '900',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0px !important',
    },
  },
  container: {},
  image: {
    width: '90%',
    height: '600px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: 5,
    boxShadow: theme.shadows['0'],
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  title: String,
  bullet: Object,
  image: String,
  buttonLabel: String,
  onButtonClick: Function,
  variantTitle: String,
  outlined: Boolean,
  imageOnRight: Boolean,
};

function SectionWho(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { title, bullet, image, buttonLabel, onButtonClick, variantTitle, outlined, imageOnRight } =
    props;

  return (
    <Grid container className={classes.layout}>
      <Grid
        spacing={2}
        container
        style={{ flexDirection: imageOnRight ? 'row-reverse' : 'row', alignItems: 'center' }}
      >
        <Hidden only={['sm', 'xs']}>
          <Grid item md={6} xs={12} className={classes.container}>
            <div
              className={classes.image}
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          </Grid>
        </Hidden>
        <Grid item md={6} xs={12} className={classes.item}>
          {title && (
            <Typography
              variant={variantTitle || 'h2'}
              component="h2"
              align="left"
              className={classes.sectionTitle}
              style={{ marginLeft: 48 }}
              gutterBottom
            >
              {t(title)}
            </Typography>
          )}
          <BulletPointContainer benefits={bullet} />
          {buttonLabel && (
            <Grid align="right">
              <Button
                className={classes.button}
                onClick={onButtonClick}
                variant={outlined ? 'outlined' : 'text'}
              >
                {t(buttonLabel)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SectionWho;
