import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';

import { InstantSearch, Index, Configure, connectAutoComplete } from 'react-instantsearch-dom';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import SearchIcon from '@material-ui/icons/Search';

import AutoCompleteAlgolia from './AutoCompleteAlgolia';
import {
  searchClient,
  INDEX_NAMES,
  FILTERING_ATTRIBUTES,
  sluggifyType,
} from '../../../Services/AlgoliaHelper';
import AutoCompletePlaces from '../AutoCompletePlaces';

type Props = {
  address: String,
  city: String,
  latitude: String,
  longitude: String,
  bbox: Array,
  service: String,
  className: Object,
  type: String,
  goBackgrounColor: String,
  disableGo: Boolean,
  searchAttributes: Object,
  defaultType: String,
  showPathologiesFilter: Boolean,
  pathologyParam: String,
  ageParam: String,
  geographicLimits: Array,
  showPathologiesMormal: Boolean,
  hideCityField: Boolean,
  hideKeywordField: Boolean,
  AutoCompleteWidth: Number,
  targetBlank: Boolean,
  placeHolder: String,
  disabled: Boolean,
  align: String,
  countries: String,
};

const useStyles = makeStyles(theme => ({
  heroForm: {
    width: '100%',
  },
  pathologiesContainer: {
    display: 'flex',
    float: 'left',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  pathologiesSection: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    flexGrow: 0,
    flexShrink: 1,
  },
  ageSection: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
    flexShrink: 0,
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    color: '#fff',
    flexShrink: 0,
    flexGrow: 1,
  },
  chip: {
    border: 'solid 1px #fff',
    color: 'white',
    marginRight: 4,
    marginBottom: 4,
    height: 28,
  },
  chipSelected: {
    backgroundColor: '#fff',
    border: 'solid 1px #fff',
    marginRight: 4,
    marginBottom: 4,
    height: 28,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
}));

function SearchBar({
  address: inFormattedAddress,
  city,
  latitude,
  longitude,
  bbox,
  service,
  className,
  type,
  defaultType,
  goBackgrounColor,
  disableGo,
  geographicLimits,
  searchAttributes,
  showPathologiesFilter,
  pathologyParam,
  ageParam,
  showPathologiesMormal,
  hideCityField,
  hideKeywordField,
  AutoCompleteWidth,
  targetBlank,
  placeHolder,
  disabled,
  align,
  countries,
}: Props) {
  const [serviceName, setServiceName] = React.useState(service);
  const currentWord = React.useRef(service);
  const inAddress = {
    formattedAddress: inFormattedAddress,
    locality: city,
    latitude,
    longitude,
    bbox,
  };
  const [address, setAddress] = React.useState(inAddress);
  const [pathology, setPathology] = React.useState(pathologyParam);
  const [age, setAge] = React.useState(ageParam);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const AutoComplete = connectAutoComplete(AutoCompleteAlgolia);
  const pathologiesTree = {
    'Maladies chroniques': {},
    'Maladies neuro-évolutives': {},
    Handicap: {},
    Autre: {},
  };
  const buttonRef = React.useRef();
  const pathologiesMormal = ['Personnes Agées', 'Handicap', 'Aidants'];

  React.useEffect(() => {
    setServiceName(service);
  }, [service]);

  const handleSubmit = () => {
    if (disableGo) return;
    const root = `/recherche/${type ? sluggifyType(type) : defaultType}`;
    let params = '';

    if (!_isEmpty(currentWord.current)) {
      params += `&service=${currentWord.current}`;
    }

    const { locality } = address;

    if (!_isEmpty(locality)) {
      params += `&city=${address.locality}&address=${address.formattedAddress}&lat=${address.latitude}&long=${address.longitude}`;
      if (address.bbox?.length > 0) {
        const urlBbox = address.bbox.reduce((acc, item) => {
          if (acc.length === 0) {
            return `${item}`;
          }
          return `${acc};${item}`;
        }, '');
        params += `&bbox=${urlBbox}`;
      }

      if (!_isEmpty(pathology)) {
        params += `&${FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE}=${pathology}`;
      }
      if (!_isEmpty(age)) {
        params += `&age=${age}`;
      }
    }

    if (!_isEmpty(params)) params = `?${params.substring(1)}`;

    const historyToPush = `${root}${params}`;

    if (targetBlank) {
      window.open(historyToPush, '_blank', 'noopener');
    } else {
      history.push(historyToPush);
    }
  };

  return (
    <Grid className={classes.container}>
      <Grid
        container
        spacing={2}
        justifyContent={align || 'left'}
        className={className || classes.heroForm}
      >
        {!hideKeywordField && (
          <Grid item align={'left'} sm={AutoCompleteWidth || 5} xs={12}>
            <InstantSearch searchClient={searchClient} indexName={INDEX_NAMES.SERVICE}>
              <AutoComplete
                value={serviceName}
                onSuggestionSelected={(value, index) => {
                  if (index < 0) {
                    setServiceName(value.a_label);
                  }
                }}
                onChange={value => {
                  currentWord.current = value;
                }}
                onBlur={event => setServiceName(event.target.value)}
                onEnterPress={handleSubmit}
                multiSection={!!searchAttributes}
              />
              <Configure hitsPerPage={4} />
              {searchAttributes && (
                <Index indexName={INDEX_NAMES.ORGANISATION}>
                  <Configure {...searchAttributes} />
                </Index>
              )}
            </InstantSearch>
          </Grid>
        )}
        {!hideCityField && (
          <Grid item align={'left'} sm={AutoCompleteWidth || 5} xs={12} style={{ zIndex: 1011 }}>
            <AutoCompletePlaces
              onSelect={value => {
                setAddress({
                  formattedAddress: value.formatted_address,
                  latitude: value.coordinates?.lat,
                  longitude: value.coordinates?.lng,
                  locality: value.locality,
                  postcode: value.postcode,
                  bbox: value.bbox,
                });
                buttonRef.current.focus();
              }}
              onClear={() => {
                setAddress({
                  formattedAddress: '',
                  latitude: '',
                  longitude: '',
                  locality: '',
                  postcode: '',
                  bbox: [],
                });
              }}
              placeHolder={placeHolder || 'Votre ville'}
              defaultValue={address.formattedAddress}
              id="searchBarddress"
              addressTypes="place, postcode"
              geographicLimits={geographicLimits}
              // cssClass="mapbox-autocomplete-searchBar"
              disabled={disabled}
              countries={countries}
            />
          </Grid>
        )}
        <Grid item sm={1} xs={12}>
          <Fab
            color="primary"
            style={{ backgroundColor: `${goBackgrounColor}` }}
            onClick={handleSubmit}
            ref={buttonRef}
          >
            <SearchIcon
              style={goBackgrounColor === '#fff' ? { color: 'initial' } : { color: 'inherit' }}
            />
          </Fab>
        </Grid>
        {showPathologiesFilter && (
          <Grid item className={classes.pathologiesContainer}>
            <Grid className={classes.pathologiesSection}>
              <Typography
                variant="h6"
                paragraph
                align="left"
                gutterBottom
                className={classes.subtitle}
              >
                {t('APP.HOLDING.SETTINGS.GENERAL.PATHOLOGY')}
              </Typography>
              {pathologiesTree && (
                <Grid>
                  {Object.keys(pathologiesTree).map(pathKey => (
                    <Chip
                      key={pathKey}
                      variant={pathology === pathKey ? 'default' : 'outlined'}
                      className={pathology === pathKey ? classes.chipSelected : classes.chip}
                      size="small"
                      onClick={() => setPathology(pathology === pathKey ? '' : pathKey)}
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: pathology === pathKey ? 'initial' : 'white',
                            fontWeight: 600,
                          }}
                        >
                          {pathKey}
                        </Typography>
                      }
                    />
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid className={classes.ageSection}>
              <Typography
                variant="h6"
                paragraph
                align="left"
                gutterBottom
                className={classes.subtitle}
              >
                {t('APP.HOLDING.SETTINGS.GENERAL.AGE')}
              </Typography>
              <Chip
                variant={age === '-60' ? 'default' : 'outlined'}
                className={age === '-60' ? classes.chipSelected : classes.chip}
                size="small"
                onClick={() => setAge(age === '-60' ? '' : '-60')}
                label={
                  <Typography
                    variant="body2"
                    style={{
                      color: age === '-60' ? 'initial' : 'white',
                      fontWeight: 600,
                    }}
                  >
                    {t('NOT_OLD')}
                  </Typography>
                }
              />
              <Chip
                variant={age === '60' ? 'default' : 'outlined'}
                className={age === '60' ? classes.chipSelected : classes.chip}
                size="small"
                onClick={() => setAge(age === '60' ? '' : '60')}
                label={
                  <Typography
                    variant="body2"
                    style={{
                      color: age === '60' ? 'initial' : 'white',
                      fontWeight: 600,
                    }}
                  >
                    {t('OLD')}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        )}
        {showPathologiesMormal && (
          <Grid item className={classes.pathologiesContainer}>
            <Typography
              variant="h6"
              paragraph
              align="left"
              gutterBottom
              className={classes.subtitle}
            >
              Pour les situations suivantes :
            </Typography>
            <Grid>
              {pathologiesMormal.map(pathKey => (
                <Chip
                  key={pathKey}
                  variant={pathology === pathKey ? 'default' : 'outlined'}
                  className={pathology === pathKey ? classes.chipSelected : classes.chip}
                  size="small"
                  onClick={() => setPathology(pathology === pathKey ? '' : pathKey)}
                  label={pathKey}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default SearchBar;
