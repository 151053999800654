/* eslint-disable react/no-unused-state */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import YouTube from 'react-youtube';
import classNames from 'classnames';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import { useHistory } from 'react-router';
import {
  HeaderCard,
  BulletPointContainer,
  ValueCard,
  AccordionContainer,
  PartnerCard,
} from '../../Components/Block';

import Footer from '../../Components/Layout/Footer';
import { Images } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';

import SearchBar from './SearchBar';
import SectionActivity from '../../Components/PagesLeagues/SectionActivity';
import SectionSubHero from '../../Components/PagesLeagues/SectionSubHero';
import { LEAGUE_SLUG } from '../../Services/AlgoliaHelper';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'white',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '2rem 3rem 3rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundImage: `url(${Images.mormal.jumbo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '10rem 0',
  },
  nobullets: {
    listStyleType: 'none',
  },
  heroTitle: {
    color: 'white',
    textShadow: '0.5px 0.5px #3c3c3ce8',
  },
  heroTitleLocation: {
    marginLeft: '20%',
  },
  heroForm: {
    marginTop: '1.5em',
  },
  subHeroForm: {
    backgroundColor: 'white',
    marginTop: '1.5em',
    frontSize: '32px',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  subHeroLayout: {
    width: '100%',
    height: '250px',
    margin: '3rem 0 3rem',
    backgroundImage: `url(${Images.mormal.sub_jumbo})`,
    backgroundSize: 'cover',
  },
  benefit: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Images.mormal.benefit})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  headingContainer: {
    width: '100%',
    margin: '2rem 0 3rem',
  },
  ctaProLayout: {
    paddingBottom: '60px',
    paddingTop: '60px',
    backgroundColor: theme.mormal.primary.thin,
  },
  ctaProSecondaryTitle: {
    color: theme.mormal.primary.main,
  },
  ctaProContent: {
    ...LayoutStyles.fullWidthLayout,
  },
  partnerLayout: {
    padding: theme.spacing(10),
  },
  dividerLayout: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  sponsor_partner: {
    height: 0,
    padding: '10%',
    backgroundSize: 'contain',
  },
  pushDownSections: {
    marginTop: -theme.spacing(7),
  },
  footerLayout: {
    backgroundColor: '#EEEEEE',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(0),
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
  footerText: {
    color: grey[600],
  },
  partenaireMedia: {
    width: '340px',
    height: '150px',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(1),
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  reminder: {
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
    width: '80%',
    backgroundColor: '#FFF',
    margin: '0 auto',
    marginBottom: '-15px',
    padding: '15px',
    color: 'green',
    fontWeight: 'bold',
  },
}));

function Homepage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {/* <Helmet> use default meta from i18n:
          - ${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE
          - ${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION
        */}
      <Grid container className={classes.container}>
        {/* HERO CTA SECTION */}
        <Grid item className={classes.heroLayout}>
          <Grid item className={classNames(classes.layout)}>
            <Typography variant="h1" align="center" className={classes.heroTitle} gutterBottom>
              {t('MORMAL.HOMEPAGE.TITLE')}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h2"
              align="center"
              className={classes.heroTitle}
              paragraph
            >
              {t('MORMAL.HOMEPAGE.SUBTITLE')}
            </Typography>
            {/* Search bar */}
            <SearchBar />
            <Typography
              variant="subtitle1"
              component="h2"
              align="center"
              className={classes.reminder}
              paragraph
            >
              {t('MORMAL.HOMEPAGE.TRANSPORT.REMINDER.CONTENT')}
            </Typography>
          </Grid>
        </Grid>

        {/* BENEFIT SECTION */}
        <Grid
          spacing={2}
          container
          className={classNames(classes.layout, classes.pushDownSections)}
        >
          <Grid item md={4} xs={12}>
            <HeaderCard
              img={Images.mormal.benefit_card_1}
              title="MORMAL.HOMEPAGE.BENEFIT.VALUE_1.TITLE"
              content="MORMAL.HOMEPAGE.BENEFIT.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HeaderCard
              img={Images.mormal.benefit_card_2}
              title="MORMAL.HOMEPAGE.BENEFIT.VALUE_2.TITLE"
              content="MORMAL.HOMEPAGE.BENEFIT.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HeaderCard
              img={Images.mormal.benefit_card_3}
              title="MORMAL.HOMEPAGE.BENEFIT.VALUE_3.TITLE"
              content="MORMAL.HOMEPAGE.BENEFIT.VALUE_3.CONTENT"
            />
          </Grid>
        </Grid>

        {/* YOUTUBE SECTION */}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="video-container"
        >
          <YouTube videoId="GPJ6S7l_IoI" />
        </Grid>

        {/* WHY AR WE SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.WHY.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.WHY.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item md={4} xs={12}>
              <div className={classes.benefit} />
            </Grid>
            <Grid item md={8} xs={12}>
              <BulletPointContainer
                benefits={[
                  {
                    bullet: ' ',
                    title: 'MORMAL.HOMEPAGE.WHY.VALUE_1.TITLE',
                    content: 'MORMAL.HOMEPAGE.WHY.VALUE_1.CONTENT',
                  },
                  {
                    bullet: ' ',
                    title: 'MORMAL.HOMEPAGE.WHY.VALUE_2.TITLE',
                    content: 'MORMAL.HOMEPAGE.WHY.VALUE_2.CONTENT',
                  },
                  {
                    bullet: ' ',
                    title: 'MORMAL.HOMEPAGE.WHY.VALUE_3.TITLE',
                    content: 'MORMAL.HOMEPAGE.WHY.VALUE_3.CONTENT',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* DIVIDER SECTION */}
        <Grid container className={classes.dividerLayout}>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </Grid>

        {/* 3 CHOICE SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.CHOICE.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.CHOICE.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.mormal.choice_card_1}
              title="MORMAL.HOMEPAGE.CHOICE.VALUE_1.TITLE"
              content="MORMAL.HOMEPAGE.CHOICE.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.mormal.choice_card_2}
              title="MORMAL.HOMEPAGE.CHOICE.VALUE_2.TITLE"
              content="MORMAL.HOMEPAGE.CHOICE.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.mormal.choice_card_3}
              title="MORMAL.HOMEPAGE.CHOICE.VALUE_3.TITLE"
              content="MORMAL.HOMEPAGE.CHOICE.VALUE_3.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.mormal.choice_card_4}
              title="MORMAL.HOMEPAGE.CHOICE.VALUE_4.TITLE"
              content="MORMAL.HOMEPAGE.CHOICE.VALUE_4.CONTENT"
            />
          </Grid>
        </Grid>

        {/* CALL CTA SECTION */}
        <Grid item className={classes.subHeroLayout}>
          <Grid item align="center" className={classes.layout}>
            <Typography variant="h2" align="center" className={classes.heroTitle} gutterBottom>
              {t('MORMAL.HOMEPAGE.CTA.TITLE')}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.heroTitle}
              align="center"
              gutterBottom
            >
              {t('MORMAL.HOMEPAGE.CTA.SUBTITLE')}
            </Typography>
            <Grid item sm={6}>
              <Button fullWidth className={classes.subHeroForm} variant="contained" size="large">
                {t('MORMAL.HOMEPAGE.CTA.PHONE')}
              </Button>
            </Grid>
            <Typography
              variant="subtitle1"
              component="h4"
              align="center"
              className={classNames(classes.heroTitleLocation, classes.heroTitle)}
              paragraph
            >
              {t('MORMAL.HOMEPAGE.SUBTITLE.LOCATION')}
            </Typography>
          </Grid>
        </Grid>

        {/* SERVICE ACCORDION SECTION */}
        <AccordionContainer
          title="MORMAL.HOMEPAGE.SERVICE.TITLE"
          subTitle="MORMAL.HOMEPAGE.SERVICE.SUBTITLE"
          backgroundImage={Images.mormal.service}
          imgHeight="588px"
          benefits={[
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_1.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_1.CONTENT',
              withIndex: true,
            },
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_2.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_2.CONTENT',
              withIndex: true,
            },
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_3.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_3.CONTENT',
              withIndex: true,
            },
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_4.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_4.CONTENT',
              withIndex: true,
            },
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_5.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_5.CONTENT',
              withIndex: true,
            },
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_6.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_6.CONTENT',
              withIndex: true,
            },
            {
              title: 'MORMAL.HOMEPAGE.SERVICE.VALUE_7.TITLE',
              content: 'MORMAL.HOMEPAGE.SERVICE.VALUE_7.CONTENT',
              withIndex: true,
            },
          ]}
        />

        <Grid container>
          <Grid item xs={12} className={classes.headingContainer}>
            <Divider style={{ margin: '3rem', background: 'none' }} />
            <SectionSubHero
              title1="On vous emmène ?"
              title1Color1="white"
              title1Color2="white"
              subTitle="Découvrez des sorties conviviales, des activités sportives ou de bien-être, des temps de loisirs partagés avec votre proche, des solutions de répit..."
              subTitleColor="white"
              cover={Images.mormal.jumbo_activity}
              image={Images.mormal.activity}
              buttonLabel="Découvrir les activités"
              btnColor="#3D8A45"
              onButtonClick={() => history.push('/recherche/activites')}
            />
            <Divider style={{ margin: '3rem', background: 'none' }} />
            <SectionActivity
              title="Toutes nos activités, nous pouvons vous y emmener !"
              subtitle="Activités sportives et artistiques, sorties culturelles, moments conviviaux…"
              leagueSlug={LEAGUE_SLUG.CCMormal}
            />
          </Grid>
        </Grid>

        {/* PARTNER SECTION */}
        <Grid container className={classes.partnerLayout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.PARTNER.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.PARTNER.SUBTITLE')}
              </Typography>
            </Grid>
            <Grid container align="center">
              <Grid item md={6} xs={12}>
                <PartnerCard
                  img={Images.mormal.partner}
                  title="MORMAL.HOMEPAGE.PARTNER.PARTNER_MORMAL.TITLE"
                  content="MORMAL.HOMEPAGE.PARTNER.PARTNER_MORMAL.CONTENT"
                />
                <Button color="inherit" href="https://www.cc-paysdemormal.fr/">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <PartnerCard
                  img={Images.wello.partner}
                  title="WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE"
                  content="WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT"
                />
                <Button color="inherit" href="https://www.wello.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* DIVIDER SECTION */}
        <Grid container className={classes.dividerLayout}>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </Grid>

        {/* SPONSOR SECTION */}
        <Grid container className={classes.partnerLayout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.SPONSOR.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('MORMAL.HOMEPAGE.SPONSOR.SUBTITLE')}
              </Typography>
            </Grid>

            <Grid container align="center">
              <Grid item md={6} xs={12}>
                <PartnerCard
                  img={Images.mormal.sponsor1}
                  title="MORMAL.HOMEPAGE.SONPSOR_1.TITLE"
                  content="MORMAL.HOMEPAGE.SPONSOR_1.CONTENT"
                />
                <Button color="inherit" href="https://www.penatesetcite.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <PartnerCard
                  img={Images.mormal.sponsor2}
                  title="MORMAL.HOMEPAGE.SONPSOR_2.TITLE"
                  content="MORMAL.HOMEPAGE.SPONSOR_2.CONTENT"
                />
                <Button color="inherit" href="https://www.ag2rlamondiale.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card style={{ backgroundColor: 'transparent' }} elevation={0}>
                  <CardMedia className={classes.partenaireMedia} image={Images.mormal.partner2} />
                </Card>
                <Button color="inherit" href="https://lenord.fr/jcms/j_6/accueil">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card style={{ backgroundColor: 'transparent' }} elevation={0}>
                  <CardMedia className={classes.partenaireMedia} image={Images.mormal.partner3} />
                </Card>
                <Button color="inherit" href="https://nord-pasdecalais.msa.fr/lfy">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}

export default Homepage;
