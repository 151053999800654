import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Slider from 'react-slick';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';
import CardCourse from '../Shared/Courses/CardCourse';
import AlgoliaActions from '../../Redux/AlgoliaRedux';

import { FILTERING_ATTRIBUTES } from '../../Services/AlgoliaHelper';
import { getNumberOfDays } from '../../Services/DataHelper';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 3rem 3rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  subTitle: {
    marginBottom: theme.spacing(6),
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(4),
  },
}));

type Props = {
  title: String,
  subTitle: String,
  buttonLabel: String,
  onButtonClick: Function,
  leagueSlug: String,
};

function SectionMooc({ title, subTitle, buttonLabel, onButtonClick, leagueSlug }: Props) {
  const dispatch = useDispatch();
  const coursesHits = useSelector(state => state.algolia.courses);
  const reduxQueryParams = useSelector(state => state.algolia.queryParams?.getAlgoliaCourses);
  const lastFetchDate = useSelector(state => state.algolia.lastFetchDate?.getAlgoliaCourses);
  const queryParams = {
    hitsPerPage: 5,
    filters: `${FILTERING_ATTRIBUTES.POSTABLE_TYPE_ATTRIBUTE}:League AND ${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${leagueSlug}`,
  };

  React.useEffect(() => {
    if (
      !coursesHits ||
      coursesHits?.length === 0 ||
      reduxQueryParams?.filters !== queryParams.filters ||
      getNumberOfDays(lastFetchDate, new Date()) > 7
    ) {
      dispatch(AlgoliaActions.getAlgoliaCoursesRequest(queryParams));
    }
  }, []);

  return (
    <CoursesHits
      hits={coursesHits}
      title={title}
      subTitle={subTitle}
      buttonLabel={buttonLabel}
      onButtonClick={onButtonClick}
    />
  );
}

export default SectionMooc;

type HitsProps = {
  hits: Array,
  title: String,
  subTitle: String,
  buttonLabel: String,
  onButtonClick: Function,
};

const orderCourses = (a, b) => {
  if (!a.created_at) return 1;
  if (!b.created_at) return -1;
  return moment(b.created_at) - moment(a.created_at);
};

const CoursesHits = ({ hits, title, subTitle, buttonLabel, onButtonClick }: HitsProps) => {
  const courses = hits?.map(hit => ({
    id: hit.objectID,
    description: hit.a_description,
    title: hit.a_title,
    cover_image_url: hit.a_cover_image_url,
    duration: hit.a_duration,
    postable_name: hit.a_postable_name,
    postable_id: hit.a_postable_id,
    postable_slug: hit.a_postable_slug,
    slug: hit.a_slug,
    postable_type: hit.a_postable_type,
    created_at: hit.a_created_at,
  }));
  const classes = useStyles();
  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 2,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {courses && courses.length > 0 && (
        <Grid className={classes.mainLayout}>
          <Grid className={classes.layout}>
            <Typography variant="h3" align="center" className={classes.title} gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle1" align="center" className={classes.subTitle} paragraph>
              {subTitle}
            </Typography>
            <Slider {...settings}>
              {courses.sort(orderCourses).map(course => (
                <Paper key={course.id} className={classes.paper} elevation={0}>
                  <CardCourse
                    course={course}
                    linkUrl={`/formations/${course.slug}?goBack=search`}
                  />
                </Paper>
              ))}
            </Slider>
            <Grid align="right">
              <Button onClick={onButtonClick} className={classes.btn}>
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
