import React from 'react';
import { withTranslation } from 'react-i18next';

import _ from 'lodash';
import fp from 'lodash/fp';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';

import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { formatHours } from '../../Services/DataHelper';

const styles = () => ({
  fab: {
    margin: 'auto 0',
  },
  root: {
    fontSize: '0.7rem',
  },
});

export const DEFAULT_HOUR_START = '09:00';
export const DEFAULT_HOUR_END = '18:00';

type Props = {
  classes: Object,
  name: String,
  // eslint-disable-next-line react/no-unused-prop-types
  value: Object,
  onChange: Function,
  t: Function,
};

class InputMultiHours extends React.Component<Props> {
  constructor(props) {
    super(props);
    const hours = formatHours(props.value);
    const hoursState = _.isArray(hours)
      ? {
          monday: _.get(hours, '[0]'),
          tuesday: _.get(hours, '[1]'),
          wednesday: _.get(hours, '[2]'),
          thursday: _.get(hours, '[3]'),
          friday: _.get(hours, '[4]'),
          saturday: _.get(hours, '[5]'),
          sunday: _.get(hours, '[6]'),
        }
      : {};
    this.state = _.defaults(hoursState, {
      monday: [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
      tuesday: [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
      wednesday: [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
      thursday: [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
      friday: [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
      saturday: null,
      sunday: null,
    });
  }

  handleInputChange = (name, key, type) => event => {
    const { target } = event;
    const { value } = target;
    const { [name]: day } = this.state;

    let index = 0;
    const dayUpdated = fp.map(hour => {
      // eslint-disable-next-line no-plusplus
      index++;
      if (index - 1 === key) {
        return { ...hour, [type]: value };
      }
      return hour;
    })(day);
    this.setState({ [name]: dayUpdated }, this.handleOnChange);
  };

  handleCheckboxChange = name => event => {
    const {
      target: { checked },
    } = event;
    this.setState(
      { [name]: checked ? [{ start: '09:00', end: '18:00' }] : null },
      this.handleOnChange,
    );
  };

  handleAddHour = name => () => {
    const { [name]: day } = this.state;
    this.setState({ [name]: [...day, { start: '14:00', end: '18:00' }] }, this.handleOnChange);
  };

  handleOnChange = () => {
    const { name, onChange } = this.props;
    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.state;
    const value = JSON.stringify([monday, tuesday, wednesday, thursday, friday, saturday, sunday]);
    const event = { target: { value, name } };
    onChange(event);
  };

  renderDay = (hours, name) => {
    const { classes, t } = this.props;
    return (
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={3}>
          <FormControl component="div" fullWidth>
            <FormControlLabel
              component="div"
              label={t(`${name.toUpperCase()}`)}
              control={
                <Checkbox
                  name="day"
                  onChange={this.handleCheckboxChange(name)}
                  checked={hours}
                  color="primary"
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          {hours &&
            hours.map((hour, key) => (
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                // eslint-disable-next-line react/no-array-index-key
                key={`${name}-${key}`}
              >
                <Grid item xs={1}>
                  de
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id={`${name}-${key}-end`}
                    name={`${name}-${key}-start`}
                    label="HH:MM"
                    type="time"
                    value={hour.start}
                    defaultValue="08:00"
                    className={classes.textField}
                    onChange={this.handleInputChange(name, key, 'start')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  à
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id={`${name}-${key}-end`}
                    name={`${name}-${key}-end`}
                    label="HH:MM"
                    type="time"
                    value={hour.end}
                    defaultValue="18:00"
                    onChange={this.handleInputChange(name, key, 'end')}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                      pattern: 'HH:mm',
                    }}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
        {hours && hours.length < 2 && (
          <Fab
            color="secondary"
            size="small"
            aria-label="add"
            className={classes.fab}
            onClick={this.handleAddHour(name)}
          >
            <AddIcon />
          </Fab>
        )}
      </Grid>
    );
  };

  render() {
    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.state;

    return (
      <>
        {this.renderDay(monday, 'monday')}
        {this.renderDay(tuesday, 'tuesday')}
        {this.renderDay(wednesday, 'wednesday')}
        {this.renderDay(thursday, 'thursday')}
        {this.renderDay(friday, 'friday')}
        {this.renderDay(saturday, 'saturday')}
        {this.renderDay(sunday, 'sunday')}
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(InputMultiHours));
