import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  needLayout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 0rem 2rem 0rem',
    justifyContent: 'center',
  },
  itemLayout: {
    padding: '1rem 1rem 1rem 1rem',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  needTitle: {
    padding: theme.spacing(1, 1, 0, 2),
  },
  needImage: {
    width: 'auto',
    height: 50,
  },
  needIcon: {
    backgroundColor: '#fff',
    width: 50,
    height: 50,
    '&>*': {
      width: 50,
      height: 50,
    },
  },
  title: {
    width: '70%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(4),
  },
}));

type Props = {
  title: String,
  needs: Array,
  rowItemSM: Number,
  buttonLabel: String,
  onButtonClick: Function,
  iconColor: String,
};

function SectionMatrice(props: Props) {
  const classes = useStyles();
  const { title, rowItemSM, needs, buttonLabel, onButtonClick, iconColor } = props;

  return (
    <Grid align="center" className={classes.layout}>
      {title && (
        <Typography
          variant="h3"
          component="h3"
          align="center"
          gutterBottom
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      <Grid container className={classes.needLayout}>
        {needs.map(need => (
          <Grid key={need.title} item sm={rowItemSM} xs={12} className={classes.itemLayout}>
            {need.image && <img src={need.image} className={classes.needImage} alt={need.title} />}
            {need.icon && (
              <Avatar className={classes.needIcon} style={{ color: `${iconColor}` }}>
                {need.icon}
              </Avatar>
            )}
            <Grid>
              {need.title && (
                <Typography
                  variant="h6"
                  component="h4"
                  align="left"
                  gutterBottom
                  className={classes.needTitle}
                >
                  {need.title}
                </Typography>
              )}
              {need.subTitle && (
                <Typography
                  variant="body"
                  component="p"
                  align="left"
                  gutterBottom
                  className={classes.needTitle}
                >
                  {need.subTitle}
                </Typography>
              )}
            </Grid>
          </Grid>
        ))}
        {buttonLabel && (
          <Grid align="center">
            <Button
              onClick={onButtonClick}
              variant="outlined"
              disableElevation
              className={classes.btn}
            >
              {buttonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
export default SectionMatrice;
