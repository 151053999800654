import React from 'react';
import moment from 'moment';
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom';
import Grid from '@material-ui/core/Grid';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';
import CardArticle from '../Shared/Articles/CardArticle';

import { searchClient, INDEX_NAMES, FILTERING_ATTRIBUTES } from '../../Services/AlgoliaHelper';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 3rem 3rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem',
    },
  },
  rootArticle: {
    padding: theme.spacing(1, 1),
  },
  subTitle: {
    width: '70%',
    margin: 'auto',
    marginBottom: theme.spacing(6),
  },
  btn: {
    marginTop: theme.spacing(4),
  },
}));

type Props = {
  title: String,
  subTitle: String,
  buttonLabel: String,
  onButtonClick: Function,
  leagueSlug: String,
};

function SectionArticles({ title, subTitle, buttonLabel, onButtonClick, leagueSlug }: Props) {
  return (
    <InstantSearch searchClient={searchClient} indexName={INDEX_NAMES.ARTICLE}>
      <Configure
        hitsPerPage={5}
        filters={`${FILTERING_ATTRIBUTES.POSTABLE_TYPE_ATTRIBUTE}:League AND ${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${leagueSlug}`}
      />
      <CustomArticlesHits
        title={title}
        subTitle={subTitle}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
      />
    </InstantSearch>
  );
}

export default SectionArticles;

type HitsProps = {
  hits: Array,
  title: String,
  subTitle: String,
  buttonLabel: String,
  onButtonClick: Function,
};

const orderArticles = (a, b) => {
  if (!a.created_at) return 1;
  if (!b.created_at) return -1;
  return moment(b.created_at) - moment(a.created_at);
};

const ArticlesHits = ({ hits, title, subTitle, buttonLabel, onButtonClick }: HitsProps) => {
  const articles = hits?.map(hit => ({
    slug: hit.a_slug,
    content: hit.a_content,
    title: hit.a_title,
    related_words: hit.a_related_words,
    created_at: hit.a_created_at,
    updated_at: hit.a_updated_at,
    cover_image_url: hit.a_cover_image_url,
    postable_id: hit.a_postable_id,
    postable_slug: hit.a_postable_slug,
    visibility: hit.a_visibility,
    postable_type: hit.a_postable_type,
    postable_name: hit.a_postable_name,
  }));
  const classes = useStyles();
  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 2,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {articles && articles.length > 0 && (
        <Grid className={classes.mainLayout}>
          <Grid className={classes.layout}>
            <Typography variant="h3" align="center" className={classes.title} gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle1" align="center" className={classes.subTitle} paragraph>
              {subTitle}
            </Typography>
            <Slider {...settings}>
              {articles
                .filter(article => article?.visibility === 'public')
                .sort(orderArticles)
                .map(article => (
                  <Paper className={classes.rootArticle} key={article.slug} elevation={0}>
                    <CardArticle
                      article={article}
                      canEdit={false}
                      linkUrl={`/articles/${article.slug}`}
                      showPostable
                    />
                  </Paper>
                ))}
            </Slider>
            <Grid align="right">
              <Button onClick={onButtonClick} className={classes.btn}>
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const CustomArticlesHits = connectHits(ArticlesHits);
