import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useRouteMatch, useLocation } from 'react-router-dom';
import 'moment/locale/fr';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../../Components/Layout/Footer';
import { muiTheme } from '../../Themes';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../Routing/Routes';

import Seo from '../../Routing/Seo';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';

import {
  CustomHitsOrganisation,
  CustomHitsTwig,
  CustomHitsArticle,
  CustomHitsCourse,
  CustomClubs,
  CustomHitsCommunity,
} from '../../Components/Shared/Algolia/CustomHits';

import {
  INDEX_NAMES,
  FILTERING_ATTRIBUTES,
  SEARCH_BAR_SEARCH_ATTRIBUTES,
  LEAGUE_SLUG,
  GEOGRAPHIC_LIMITS,
  formatType,
  wordifyType,
} from '../../Services/AlgoliaHelper';
import InstantSearchResults from '../../Components/Shared/Algolia/InstantSearchResults';

const useStyles = makeStyles(theme => ({
  activityUnit: {
    paddingBottom: theme.spacing(3),
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  subHeroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundColor: theme.soss.secondary.main,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: '60%',
    padding: '3rem 0',
  },
  headingContainer: {
    width: '100%',
    padding: '3rem 0 3rem',
  },
  searchBar: {
    marginTop: 0,
  },
  results: {
    minHeight: '60vh',
  },
}));

function SearchResult() {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    params: { type_slug: typeSlug },
  } = match;
  const type = formatType(typeSlug);
  const typeName = wordifyType(typeSlug);
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    serviceSlug: queryParam.get('service') || '',
    city: queryParam.get('city') || null,
    address: queryParam.get('address') || '',
    latitude: queryParam.get('lat') || null,
    longitude: queryParam.get('long') || null,
    bbox: queryParam.get('bbox') || null,
  };
  const position = urlParams.latitude != null && urlParams.longitude != null;
  const bbox = urlParams.bbox?.split(';')?.map(val => parseFloat(val, 10)) || null;

  const typeAttributes = {
    organisations: {
      indexName: INDEX_NAMES.ORGANISATION,
      title: 'APP.SEARCH_RESULT_PAGE.ORGANISATIONS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: false,
      configureSettings: {
        hitsPerPage: 15,
        query: urlParams.serviceSlug,
        getRankingInfo: true,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SOSS}`,
        // AND ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Association' OR ${FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE}: 'Organisations::Pfr'
      },
      HitsDisplay: CustomHitsOrganisation,
      hasMap: true,
      refinementAttributes: [
        {
          label: 'Types de structure',
          attribute: FILTERING_ATTRIBUTES.ORGANISATION_TYPE_ATTRIBUTE,
        },
      ],
      hideKeywordField: false,
      hideCityField: false,
    },
    twigs: {
      indexName: INDEX_NAMES.TWIG,
      title: 'APP.SEARCH_RESULT_PAGE.TWIGS',
      aroundCityPrefix: 'APP.SEARCH_RESULT_PAGE.CLOSE_TO',
      showCityInfo: true,
      showKeywordInfo: true,
      configureSettings: {
        hitsPerPage: 12,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SOSS}`,
      },
      HitsDisplay: CustomHitsTwig,
      hasMap: true,
      refinementAttributes: [
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
          attribute: FILTERING_ATTRIBUTES.TWIG_EXPERTISE_SLUG_ATTRIBUTE,
          limit: 30,
          showMoreLimit: 30,
        },
        {
          label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.PATHOLOGY'),
          attribute: FILTERING_ATTRIBUTES.ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE,
        },
      ],
    },
    articles: {
      indexName: INDEX_NAMES.ARTICLE,
      title: 'APP.SEARCH_RESULT_PAGE.ARTICLES',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.SOSS}`,
      },
      HitsDisplay: CustomHitsArticle,
      hasMap: false,
      showKeywordInfo: true,
      // refinementAttributes: [
      //   {
      //     label: t('APP.SEARCH_RESULT_PAGE.REFINEMENT.EXPERTISE'),
      //     attribute: FILTERING_ATTRIBUTES.ARTICLE_EXPERTISE_FACET_ATTRIBUTE,
      //   },
      // ],
    },
    courses: {
      indexName: INDEX_NAMES.COURSE,
      title: 'SOSS.SEARCH_RESULT_PAGE.TAB.COURSES',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.POSTABLE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.SOSS}`,
      },
      HitsDisplay: CustomHitsCourse,
      hasMap: false,
      hideKeywordField: false,
      hideCityField: false,
      showKeywordInfo: true,
    },
    clubs: {
      indexName: INDEX_NAMES.COMMUNITY,
      title: 'APP.SEARCH_RESULT_PAGE.CLUBS',
      configureSettings: {
        hitsPerPage: 10,
        query: urlParams.serviceSlug,
        filters: `${FILTERING_ATTRIBUTES.LEAGUE_SLUG_ATTRIBUTE}:${LEAGUE_SLUG.SOSS}`,
      },
      FallBackComponent: CustomClubs,
      HitsDisplay: CustomHitsCommunity,
      hasMap: false,
      hideKeywordField: false,
      hideCityField: true,
    },
  };

  return (
    <Grid>
      <Helmet>
        {urlParams.city &&
          ['organisations', 'twigs'].includes(type) &&
          Seo.title(
            `${typeName} à ${urlParams.city}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`,
          )}
        {(!urlParams.city || !['organisations', 'twigs'].includes(type)) &&
          Seo.title(`Résultats ${typeName}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
        {Seo.canonical(
          Routes.currentLeague().id,
          `${location.pathname}${location.search.replaceAll(' ', '%20')}`,
        )}
      </Helmet>
      <Hidden only={['xs']}>
        <Grid container className={classes.container}>
          <Grid item className={classes.subHeroLayout}>
            <Grid item className={classes.layout} style={{ paddingTop: 0 }}>
              <SearchBar
                className={classes.searchBar}
                service={urlParams.serviceSlug}
                address={urlParams.address}
                city={urlParams.city}
                latitude={urlParams.latitude}
                longitude={urlParams.longitude}
                bbox={bbox}
                type={type}
                goBackgrounColor={muiTheme.soss.primary.main}
                geographicLimits={GEOGRAPHIC_LIMITS.SOSS}
                searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.SOSS}
                defaultType="structures"
                hideKeywordField={typeAttributes[type].hideKeywordField}
                hideCityField={typeAttributes[type].hideCityField}
                countries="lu"
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Grid className={classes.results}>
        <InstantSearchResults
          request={typeAttributes[type]}
          position={position ? { lat: urlParams.latitude, lng: urlParams.longitude } : null}
          bbox={bbox}
          typeAttributes={typeAttributes}
          tabs={[
            {
              label: t('APP.SEARCH_RESULT_PAGE.TAB.ORGANISATIONS'),
              value: 'organisations',
            },
            { label: t('APP.SEARCH_RESULT_PAGE.TAB.TWIGS'), value: 'twigs' },
            { label: t('APP.SEARCH_RESULT_PAGE.TAB.ARTICLES'), value: 'articles' },
            { label: t('APP.SEARCH_RESULT_PAGE.TAB.CLUBS'), value: 'clubs' },
            { label: t('SOSS.SEARCH_RESULT_PAGE.TAB.COURSES'), value: 'courses' },
          ]}
        />
      </Grid>
      <Footer />
    </Grid>
  );
}

export default SearchResult;
