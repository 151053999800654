import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SectionHeroCenter from '../../Components/PagesLeagues/SectionHeroCenter';
import { Images, muiTheme } from '../../Themes';
import Footer from '../../Components/Layout/Footer';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import SearchBar from '../../Components/Shared/Algolia/SearchBar';
import { GEOGRAPHIC_LIMITS, SEARCH_BAR_SEARCH_ATTRIBUTES } from '../../Services/AlgoliaHelper';
import ButtonCallMeBackInModal from '../../Components/Shared/ButtonCallMeBackInModal';
import SectionNeed from '../../Components/PagesLeagues/SectionNeed';
import {
  AdministrativesIcon,
  GroupeParoleIcon,
  PairAidanceIcon,
  SoinIcon,
  RelayageIcon,
  PreventionIcon,
} from '../../Themes/Icon';
import SectionContentOffer from '../../Components/PagesLeagues/SectionContentOffer';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '4rem 2rem',
    [theme.breakpoints.only('xs')]: {
      margin: '4rem 1rem',
    },
  },
  headingContainer: {
    width: '100%',
    margin: '3rem',
  },
  searchBtn: {
    backgroundColor: theme.soss.primary.main,
    color: theme.soss.secondary.dark,
    padding: theme.spacing(1, 6),
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    margin: theme.spacing(2, 0),
  },
}));

function Homepage() {
  const classes = useStyles();

  return (
    <Grid className={classes.mainLayout}>
      {/* HERO SECTION */}
      <SectionHeroCenter
        title="Bien vivre au Luxembourg"
        backgroundImage={`url(${Images.soss.jumbo})`}
        titleColor={muiTheme.soss.primary.main}
        subTitle="Dispositif innovant de coodination des intervenants à domicile"
        backgroundColor="#f5f5f5"
      >
        <SearchBar
          goBackgrounColor={muiTheme.soss.primary.main}
          geographicLimits={GEOGRAPHIC_LIMITS.SOSS}
          searchAttributes={SEARCH_BAR_SEARCH_ATTRIBUTES.SOSS}
          defaultType="structures"
          hideKeywordField
          AutoCompleteWidth={9}
          align="center"
          countries="lu"
        />
        <Typography variant="body1" component="h3" className={classes.marginBottom}>
          Vous souhaitez mettre en place un service de coordination à domicile ?
        </Typography>

        <ButtonCallMeBackInModal
          withContainedButton
          // organisation={organisation} // send to league admin
          withPostCode
          withEmail
          withPhone
          buttonLabel="CONTACT_US"
          descriptionPlaceHolder="ex: Je souhaite mettre en place un service de coordination à domicile"
          backgroundColor={muiTheme.soss.secondary.main}
          defaultCountryCode="lu"
          gtmOrigin="landing"
        />
      </SectionHeroCenter>

      <Hidden only={['xs']}>
        <Divider style={{ margin: '4rem', background: 'none' }} />
      </Hidden>

      <SectionContentOffer
        title="Bien vivre au Luxembourg"
        paragraph="<p>UNE PLATEFORME D’ENTRAIDE POUR LE MAINTIEN A DOMICILE</p><p>La plateforme bien vivre au Luxembourg permet aux acteurs du médico-social de partager des informations et de collaborer plus efficacement autour des personnes en situation de perte d’autonomie afin de leur permettre de rester chez elle tout en maintenant leur qualité de vie.</p><p>Cette plateforme est accessible aux personnes aidées ainsi qu’à leurs aidants, leur famille et tout professionnel qui intervient dans leur accompagnement : médecins, infirmières, aide à domicile, kinésithérapeute, etc.</p>"
        image={Images.soss.who}
        imageOnLeft
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionNeed
        title="Les objectifs de la plateforme"
        rowItemSM={4}
        needs={[
          {
            icon: <PairAidanceIcon />,
            subTitle:
              'Améliorer la qualité des soins en permettant aux professionnels de collaborer plus efficacement',
          },
          {
            icon: <PreventionIcon />,
            subTitle:
              'Evaluer les besoins des personnes en situation de perte d’autonomie de manière multidimensionnelle',
          },
          {
            icon: <AdministrativesIcon />,
            subTitle:
              'Accélérer la prise de décision en permettant aux professionnels de disposer rapidement de toutes les informations pertinentes',
          },
          {
            icon: <SoinIcon />,
            subTitle:
              "Faciliter l'accès aux soins pour les personnes en perte d'autonomie et leurs familles",
          },
          {
            icon: <RelayageIcon />,
            subTitle:
              'Permettre aux personnes accompagnées de rester chez elles le plus longtemps possible',
          },
          {
            icon: <GroupeParoleIcon />,
            subTitle:
              'Soutenir la famille et les proches aidants des personnes en situation de perte d’autonomie',
          },
        ]}
        iconColor={muiTheme.soss.primary.main}
      />

      <Divider style={{ margin: '4rem', background: 'none' }} />

      <SectionContentOffer
        title="Le bénéficiaire au coeur de son accompagnement"
        paragraph="<p>Chaque personne accompagnée aura des besoins différents. Ces besoins pourront être multipes et faire appel à différentes équipes de professionnels. De plus, il est essentiel de prendre en compte l’accompagnement social des personnnes en situation d’autonomie et de ne pas seulement répondre à leurs enjeux de santé. C’est pourquoi, autour de chaque personne accompagnée, il est possible de créer un ecosystème d’acteurs qui regroupe les différentes structures professionnelles, associatives, ainsi que le réseau interpersonnel de la personne (famille, amis, voisins, etc.)</p><p>La plateforme bien vivre au Luxembourg permet aux acteurs du médico-social de partager des informations et de collaborer plus efficacement autour des personnes en situation de perte d’autonomie afin de leur permettre de rester chez elle tout en maintenant leur qualité de vie.</p><p>Les bénéficiaires sont au coeur de leur accompagnement, ce qui permet de leur assurer une prise en charge globale, cohérente et efficace.</p>"
        image={Images.soss.marguerite}
        imageOnLeft
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <Grid container align="center" className={classes.layout}>
        <Grid item className={classes.headingContainer}>
          <Typography variant="h3" align="center" gutterBottom>
            Des outils numériques innovants
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            L’ensemble des intervenants - les professionnels de santé, la famille, les associations
            - dispose d’outils innovants de suivi et de coordination, que qui leur permet d’échanger
            et de collaborer de manière sécurisée
          </Typography>
        </Grid>
        <img src={Images.soss.tools} style={{ maxWidth: '100%' }} />
      </Grid>

      <Divider style={{ margin: '4rem', background: 'none' }} />

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une inititiative née du partenariat avec Soss, Pictome et Wello"
        rowItemSM={4}
        partners={[
          {
            image: Images.soss.soss,
            titlePartner: 'Aide et accompagnement',
            contentPartner:
              "Composée d'une équipe aux disciplines et à l'expertise variées, Soss croit profondément aux valeurs qui l'animent et répond aux critères des 7 C ( Congruence, Confiance, Cohésion, Compétences, Climat, Compassion, Constance)",
            linkUrl: 'https://www.bienvivreluxembourg.lu/soss/lux',
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
            linkUrl: 'https://www.wello.fr/',
          },
          {
            image: Images.soss.pictome,
            titlePartner: 'Outil inclusif et universel',
            contentPartner:
              'Pictome est un outil qui aide à développer des stratégies d’adaptation et d’orientation. La communication devient facile et accessible pour tous. L’outil a été développé en collaboration avec des personnes souffrant de troubles cognitifs.',
            linkUrl:
              'https://www.pictome.fr/blogs/domicile-alzheimer/alzheimer-une-bequille-visuelle-pour-s-orienter-chez-soi',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
